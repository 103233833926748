<template>
  <div class="">
    <div
      v-if="ourTable.length && !isLoadingData"
      v-for="(phase, phaseIndex) in ourTable"
      :key="phaseIndex"
      class="plan"
    >
      <div
        style="
          border: 1px solid rgb(195, 195, 195);
          margin-bottom: 10px;
          background: white;
        "
      >
        <div
          style="
            background: #eaeaea !important;
            display: flex;
            align-items: center;
            padding: 10px 20px;
            gap: 20px;
          "
        >
          <div>
            <i
              :class="{
                'fa fa-chevron-up': isPhaseExpanded(phaseIndex),
                'fa fa-chevron-down': !isPhaseExpanded(phaseIndex),
              }"
              @click="togglePhase(phaseIndex)"
              class="pointer"
              style="font-size: 20px; margin-right: 10px"
            ></i>
            <span>{{ phaseIndex + 1 }}</span>
          </div>
          <h2 style="font-weight: bold; margin: 0; font-size: 18px">
            {{ phase.phase_name }}
          </h2>
        </div>
        <div
          v-if="isPhaseExpanded(phaseIndex)"
          v-for="(category, categoryIndex) in phase.categories"
          :key="categoryIndex"
        >
          <div style="margin-bottom: 20px; overflow-x: auto">
            <div
              style="
                display: flex;
                align-items: center;
                padding: 10px 20px;
                gap: 20px;
                background: white;
              "
            >
              <i
                :class="{
                  'fa fa-chevron-up': isCategoryExpanded(
                    phaseIndex,
                    categoryIndex
                  ),
                  'fa fa-chevron-down ': !isCategoryExpanded(
                    phaseIndex,
                    categoryIndex
                  ),
                }"
                @click="toggleCategory(phaseIndex, categoryIndex)"
                class="pointer"
              ></i>
              <span>{{ phaseIndex + 1 + "." + (categoryIndex + 1) }}</span>
              <div class="mt-2">
                <h4 style="font-size: 16px">{{ category.category_name }}</h4>
              </div>
            </div>
            <div
              class="table-container"
              v-if="isCategoryExpanded(phaseIndex, categoryIndex)"
              style="width: 100%"
            >
              <table class="table">
                <thead>
                  <tr style="background: white">
                    <th style="width: 10px"></th>
                    <th style="width: 20px">#</th>
                    <th style="white-space: wrap; width: 60px">
                      {{ $t("Action") }}
                      <span
                        @click="
                          emitToggleSort(
                            'action',
                            phaseIndex,
                            categoryIndex,
                            sortTypes['action']
                          )
                        "
                        style="margin-left: 5px; cursor: pointer"
                        ><i class="fa fa-sort" style="color: black"></i
                      ></span>
                    </th>
                    <th style="white-space: wrap; width: 120px">
                      {{ $t("Description") }}
                      <span
                        @click="
                          emitToggleSort(
                            'subject',
                            phaseIndex,
                            categoryIndex,
                            sortTypes['subject']
                          )
                        "
                        style="margin-left: 5px; cursor: pointer"
                        ><i class="fa fa-sort" style="color: black"></i
                      ></span>
                    </th>
                    <th style="white-space: wrap; width: 50px">
                      {{ $t("Deadline") }}
                      <span
                        @click="
                          emitToggleSort(
                            'deadline',
                            phaseIndex,
                            categoryIndex,
                            sortTypes['deadline']
                          )
                        "
                        style="margin-left: 5px; cursor: pointer"
                        ><i class="fa fa-sort" style="color: black"></i
                      ></span>
                    </th>
                    <th style="white-space: wrap; width: 70px">
                      {{ $t("Progress") }}
                      <span
                        @click="
                          emitToggleSort(
                            'advancement',
                            phaseIndex,
                            categoryIndex,
                            sortTypes['advancement']
                          )
                        "
                        style="margin-left: 5px; cursor: pointer"
                        ><i class="fa fa-sort" style="color: black"></i
                      ></span>
                    </th>
                    <th style="white-space: wrap; width: 50px">
                      {{ $t("Points") }}
                      <span
                        @click="
                          emitToggleSort(
                            'award',
                            phaseIndex,
                            categoryIndex,
                            sortTypes['award']
                          )
                        "
                        style="margin-left: 5px; cursor: pointer"
                        ><i class="fa fa-sort" style="color: black"></i
                      ></span>
                    </th>
                    <th style="white-space: wrap; width: 50px"></th>
                  </tr>
                </thead>
                <tbody v-if="isCategoryExpanded(phaseIndex, categoryIndex)">
                  <template
                    v-for="(item, index) in category.actions"
                    :key="index"
                  >
                    <tr style="background: white">
                      <td>
                        <i
                          :class="{
                            'fa fa-chevron-down': isRowExpanded(
                              phaseIndex,
                              categoryIndex,
                              index
                            ),
                            'fa fa-chevron-right': !isRowExpanded(
                              phaseIndex,
                              categoryIndex,
                              index
                            ),
                          }"
                          @click="toggleRow(phaseIndex, categoryIndex, index)"
                          class="pointer"
                          style="font-size: 8px"
                        ></i>
                      </td>
                      <td>
                        <!-- {{ item.action_details.id }}  -->
                        {{ phaseIndex + 1 }}.{{ categoryIndex + 1 }}.{{
                          index + 1
                        }}
                      </td>
                      <td
                        style="
                          word-wrap: break-word;
                          white-space: normal;
                          max-width: 60px;
                          overflow: hidden;
                        "
                      >
                        {{ item.action_details.action }}
                      </td>
                      <td
                        style="
                          word-wrap: break-word;
                          white-space: normal;
                          max-width: 120px;
                          overflow: hidden;
                        "
                      >
                        {{ item.action_details.subject }}
                      </td>
                      <td>
                        {{
                          getParentFormattedDate(item.action_details.deadline)
                        }}
                      </td>
                      <td class="d-flex">
                        <span style="white-space: nowrap">
                          {{ calculateAverageAdvancement(item, "text") }}%
                        </span>
                        <CProgress
                          class="mt-2 ml-2"
                          style="height: 5px; width: 100px"
                        >
                          <CProgressBar
                            :style="{
                              backgroundColor:
                                calculateAverageAdvancement(
                                  item,
                                  'progress'
                                ) === 100
                                  ? '#3CBC3C'
                                  : '',
                            }"
                            :value="
                              calculateAverageAdvancement(item, 'progress')
                            "
                          ></CProgressBar>
                        </CProgress>
                      </td>

                      <td>
                        <span
                          v-if="item.average_advancement === 100"
                          v-c-tooltip="{ content: 'Success', placement: 'top' }"
                          ><i class="fa fa-check" style="color: #3c6444"></i
                        ></span>
                        <span v-else
                          >+{{ calculateSumOfPoints(item) }} pts</span
                        >
                      </td>
                      <td>
                        <div style="margin-left: 5px">
                          <span
                            v-if="userCanEditAction()"
                            v-c-tooltip="{
                              content: $t('Edit Action'),
                              placement: 'top',
                            }"
                            data-testid="editActionIcon"
                            @click="openEditModal(phase, category, item)"
                            style="
                              color: #3399ff;
                              cursor: pointer;
                              font-size: 12px;
                            "
                            >Edit</span
                          >
                          <el-popconfirm
                            v-if="userCanDeleteAction()"
                            :title="$t('Are you sure to delete ')"
                            @confirm="openDeleteModal(item, null)"
                          >
                            <template #reference>
                              <el-button
                                style="margin-left: 10px"
                                link
                                size="small"
                                type="danger"
                                >Delete</el-button
                              >
                            </template>
                          </el-popconfirm>
                        </div>
                      </td>
                    </tr>
                    <template
                      v-if="
                        isRowExpanded(phaseIndex, categoryIndex, index) &&
                        filterUsersBySelectedMembers(item.users).length > 0
                      "
                    >
                      <tr
                        style="
                          background: #f7fafe;
                          color: #9d9d9d;
                          height: 65px;
                        "
                      >
                        <th></th>
                        <th></th>

                        <th>
                          {{ $t("Job description") }}
                        </th>
                        <th>
                          {{ $t("FirstName") }}
                        </th>
                        <th>
                          {{ $t("LastName") }}
                        </th>
                        <th>
                          {{ $t("Progress") }}
                        </th>
                        <th>
                          {{ $t("Awards") }}
                        </th>
                        <th></th>
                      </tr>

                      <tr
                        v-for="(
                          value, innerIndex
                        ) in filterUsersBySelectedMembers(item.users)"
                        :key="`${index}-${innerIndex}`"
                        style="
                          background: #f7fafe;
                          height: 65px;
                          border-bottom: 1px solid #ebeef5;
                        "
                      >
                        <td></td>
                        <td></td>
                        <td>
                          {{ value.job_description }}
                        </td>
                        <td>
                          {{ value.firstname }}
                        </td>
                        <td>
                          {{ value.lastname }}
                        </td>
                        <td>
                          <div class="d-flex" style="white-space: nowrap">
                            <input
                              type="number"
                              style="
                                width: 50px;
                                height: 20px;
                                margin-right: 5px;
                              "
                              v-model="value.advancement"
                              @change="
                                updateAdvancementMultiple(
                                  item,
                                  value.advancement,
                                  phase.phase_name,
                                  category.category_name,
                                  item.action_details.action,
                                  value.job_description,
                                  value.level_name,
                                  value.email
                                )
                              "
                              min="0"
                              max="100"
                              :disabled="!userCanEditProgress(value)"
                            />
                            %
                            <CProgress
                              class="mt-2 ml-2"
                              style="height: 5px; width: 100px"
                            >
                              <CProgressBar
                                :style="{
                                  backgroundColor:
                                    value.advancement === 100 ? '#3CBC3C' : '',
                                }"
                                :value="parseFloat(value.advancement)"
                              ></CProgressBar>
                            </CProgress>
                          </div>
                        </td>
                        <td>+{{ value.award ? value.award : 0 }} pts</td>

                        <td>
                          <span
                            v-if="userCanEditAction(value)"
                            v-c-tooltip="{
                              content: $t('Edit Action'),
                              placement: 'top',
                            }"
                            data-testid="editActionIcon"
                            @click="
                              openUniqueEditModal(phase, category, item, value)
                            "
                            style="
                              color: #3399ff;
                              cursor: pointer;
                              font-size: 12px;
                              margin-right: 5px;
                            "
                            >Edit</span
                          >
                          <el-popconfirm
                            v-if="userCanDeleteAction(value)"
                            :title="$t('Are you sure to delete ')"
                            @confirm="openDeleteModal(item, value)"
                          >
                            <template #reference>
                              <el-button link size="small" type="danger"
                                >Delete</el-button
                              >
                            </template>
                          </el-popconfirm>
                        </td>
                      </tr>
                    </template>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70vh;
      "
    >
      <h1 v-if="!isLoadingData">{{ $t("No actions available") }}</h1>
      <ContentLoader
        v-else
        :speed="2"
        primaryColor="rgb(230, 230, 230)"
        secondaryColor="rgb(240, 240, 240)"
        class="spinner_wrapper mt-4"
      ></ContentLoader>
    </div>
    <EditActionModal
      :show="showEditGroupedActionModal"
      :phaseOptions="phaseOptions"
      :categoryOptions="categoryOptions"
      :usersOptions="usersOptions"
      :usersModal="usersModal"
      :orgMembers="orgMembers"
      :selectedAction="selectedAction"
      :selectedPhase="selectedPhase"
      :selectedCategory="selectedCategory"
      :listOfMembers="listOfMembers"
      :isGroupedView="true"
      :allOrgLevelsOptions="allOrgLevelsOptions"
      @close="showEditGroupedActionModal = false"
    />
    <EditActionModal
      :show="showEditUniqueActionModal"
      :phaseOptions="phaseOptions"
      :categoryOptions="categoryOptions"
      :usersOptions="usersOptions"
      :usersModal="usersModal"
      :orgMembers="orgMembers"
      :selectedAction="selectedAction"
      :selectedPhase="selectedPhase"
      :selectedCategory="selectedCategory"
      :listOfMembers="listOfMembers"
      :allOrgLevelsOptions="allOrgLevelsOptions"
      :userToEdit="userToEdit"
      @close="
        showEditUniqueActionModal = false;
        userToEdit.clear();
      "
    />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { ActionsService } from "@/services/actions";
import EditActionModal from "../components/EditActionModal.vue";
import { showMessage } from "../../../utils/helpers";

export default {
  name: "ActionTable",
  components: {
    EditActionModal,
  },
  props: {
    tableItems: Array,
    isDetailsOpen: Boolean,
    selectedRowIndex: Number,
    selectedPhaseIndex: Number,
    selectedCategoryIndex: Number,
    displayedTableItems: Array,
    currentPage: Number,
    displayedItems: Number,
    totalPages: Number,
    perPage: Number,
    perOptions: Array,
    sortTypes: Object,
    isLoadingData: Boolean,
    selectedMembers: Array,
    selectedLevels: Array,
    phaseOptions: Array,
    categoryOptions: Array,
    usersOptions: Array,
    usersModal: Array,
    listOfMembers: Array,
    orgMembers: Array,
    allOrgLevelsOptions: Array,
  },
  data() {
    return {
      showEditGroupedActionModal: false,
      showEditUniqueActionModal: false,
      selectedAction: null,
      selectedPhase: null,
      selectedCategory: null,
      ourTable: [],
      isAllChecked: false,
      expandedCategories: new Set(),
      expandedPhases: new Set(),
      expandedRows: new Set(),
      userToEdit: new Set(),
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      selectedOrg: (state) => state.selectedOrg,
      selectedProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      isAdmin: (state) => state.isAdmin,
      expandedLevels: (state) => state.expandedLevels,
      permissions: (state) => state.permissions,
    }),
  },
  watch: {
    tableItems: {
      immediate: true,
      handler(newVal) {
        this.expandedCategories.clear();
        this.expandedPhases.clear();

        newVal.forEach((phase, phaseIndex) => {
          this.expandedPhases.add(phaseIndex);
          phase.categories.forEach((_, categoryIndex) => {
            this.expandedCategories.add(`${phaseIndex}-${categoryIndex}`);
          });
        });
        this.ourTable = newVal;
      },
    },
  },
  methods: {
    userCanEditAction(value) {
      return this.$parent.userCanEditAction(value);
    },
    userCanEditProgress(value) {
      return this.$parent.userCanEditProgress(value);
    },
    userCanDeleteAction(value) {
      return this.$parent.userCanDeleteAction(value);
    },
    isDisabled(permission, item) {
      if (item[permission]) {
        return false;
      }

      return true;
    },
    isDisabledGrouped(permission, item) {
      let foundFalse = false;
      item.users.forEach((user) => {
        if (!user[permission]) {
          foundFalse = true;
        }
      });
      return foundFalse;
    },
    editedAction(action) {
      this.$parent.editedAction(action);
    },
    openEditModal(phase, category, item) {
      this.selectedAction = item;
      this.selectedPhase = phase.phase_name;
      this.selectedCategory = category.category_name;
      this.showEditGroupedActionModal = true;
    },
    openUniqueEditModal(phase, category, item, value) {
      this.selectedAction = item;
      this.selectedPhase = phase.phase_name;
      this.selectedCategory = category.category_name;
      const neededString = `${value.email} - ${value.level_name} - ${value.job_description} - ${value.firstname} - ${value.lastname}`;
      this.userToEdit.add(neededString);
      this.showEditUniqueActionModal = true;
    },

    flattenLevels(levels) {
      let flattenedMembers = [];

      levels.forEach((level) => {
        // Include current level's members if any
        if (level.members && level.members.length > 0) {
          level.members.forEach((member) => {
            flattenedMembers.push({
              email: member.email,
              level_name: level.level_name,
              job_description: member.job_description,
            });
          });
        }

        // Recurse into children if any
        if (level.children && level.children.length > 0) {
          flattenedMembers = [
            ...flattenedMembers,
            ...this.flattenLevels(level.children),
          ];
        }
      });

      return flattenedMembers;
    },
    async openDeleteModal(item, user) {
      if (!user) {
        const actionId = item.action_details._id;
        const selectedLevelsArray = Array.from(this.selectedLevels);
        const flattenedMembers = this.flattenLevels(selectedLevelsArray);
        const filteredUsers = item.users.filter((user) =>
          flattenedMembers.some(
            (member) =>
              member.email === user.email &&
              member.level_name === user.level_name &&
              member.job_description === user.job_description
          )
        );
        const usersData = filteredUsers.map((u) => ({
          action_id: item.action_details.id,
          user_email: u.email,
          job_description: u.job_description,
          level_name: u.level_name,
        }));
        const dataToSend = {
          users: usersData,
        };

        const { data } = await ActionsService.delete_actions({
          users: usersData,
          _id: actionId,
        });
        if (data.success) {
          showMessage(
            "success",
            "Successfully deleted action for the selected users"
          );
        } else {
          showMessage("error", "Failed to delete action");
        }
      } else {
        const actionId = item.action_details.id;

        const dataToSend = {
          action_id: actionId,
          user_email: user.email,
          job_description: user.job_description,
          level_name: user.level_name,
          _id: item.action_details._id,
        };
        const { data } = await ActionsService.delete_action(dataToSend);
        if (data.success) {
          showMessage(
            "success",
            "Successfully deleted action for the selected user"
          );
        } else {
          showMessage("error", "Failed to delete action");
        }
      }
      this.$parent.actionDeleted(item);
    },
    toggleAllCheckboxes(category, action, event) {
      const isChecked = event.target.checked;
      this.isAllChecked = isChecked;
      const filteredUsers = this.filterUsersBySelectedMembers(action.users);
      filteredUsers.forEach((user) => {
        user.checked = isChecked;
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    calculateAverageAdvancement(item, origin) {
      const filteredUsers = this.filterUsersBySelectedMembers(item.users);

      let sumWeightedProgress = 0;
      let sumAwards = 0;

      filteredUsers.forEach((user) => {
        sumWeightedProgress += (user.advancement / 100) * user.award;
        sumAwards += parseInt(user.award);
      });
      if (origin === "text") {
        return sumAwards > 0
          ? ((sumWeightedProgress / sumAwards) * 100).toFixed(2)
          : 0;
      } else {
        return sumAwards > 0 ? (sumWeightedProgress / sumAwards) * 100 : 0;
      }
    },
    collectEmails(node, emailSet) {
      if (node.members) {
        node.members.forEach((member) => emailSet.add(member.email));
      }
      if (node.children) {
        node.children.forEach((child) => this.collectEmails(child, emailSet));
      }
    },
    filterUsersBySelectedMembers(users) {
      const validEmails = new Set();
      const validLevelNames = new Set();

      const collectEmailsFromLevels = (level) => {
        if (level.members) {
          level.members.forEach((member) => {
            validEmails.add(member.email);
            validLevelNames.add(level.level_name);
          });
        }
        if (level.children) {
          level.children.forEach((child) => collectEmailsFromLevels(child));
        }
      };

      if (this.selectedMembers.size !== 0) {
        Array.from(this.selectedMembers).forEach((member) => {
          const emailMatch = member.match(/^[^\s]+/);
          if (emailMatch) {
            validEmails.add(emailMatch[0]);
          }
        });
      } else {
        this.selectedLevels.forEach((level) => {
          collectEmailsFromLevels(level);
        });
      }
      return users.filter(
        (user) =>
          validEmails.has(user.email) && validLevelNames.has(user.level_name)
      );
    },
    formatAdvancement(value) {
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(1);
      }
    },
    calculateSumOfPoints(item) {
      const filteredUsers = this.filterUsersBySelectedMembers(item.users);

      const sumPoints = filteredUsers.reduce((sum, user) => {
        return sum + (parseInt(user.award) || 0);
      }, 0);

      return sumPoints;
    },
    previousPage() {
      this.$emit("previous-page");
    },
    nextPage() {
      this.$emit("next-page");
    },
    changePerPage(value) {
      this.$emit("change-per-page", value);
      this.expandedCategories.clear(); // Clear expanded categories when changing perPage
      this.expandedPhases.clear(); // Clear expanded phases when changing perPage
      this.expandedRows.clear(); // Clear expanded rows when changing perPage
    },
    updateAdvancementMultiple(
      item,
      advancement,
      phase,
      category,
      action,
      job_description,
      level_name,
      email
    ) {
      this.$parent.updateAdvancementMultiple(
        item,
        advancement,
        phase,
        category,
        action,
        job_description,
        level_name,
        email
      );
    },
    togglePhase(phaseIndex) {
      if (this.expandedPhases.has(phaseIndex)) {
        this.expandedPhases.delete(phaseIndex);
        // Collapse all categories in the phase
        this.ourTable[phaseIndex].categories.forEach((_, categoryIndex) => {
          this.expandedCategories.delete(`${phaseIndex}-${categoryIndex}`);
        });
      } else {
        this.expandedPhases.add(phaseIndex);
        // Expand all categories in the phase
        this.ourTable[phaseIndex].categories.forEach((_, categoryIndex) => {
          this.expandedCategories.add(`${phaseIndex}-${categoryIndex}`);
        });
      }
    },
    toggleCategory(phaseIndex, categoryIndex) {
      const key = `${phaseIndex}-${categoryIndex}`;
      if (this.expandedCategories.has(key)) {
        this.expandedCategories.delete(key);
      } else {
        this.expandedCategories.add(key);
      }
    },
    toggleRow(phaseIndex, categoryIndex, rowIndex) {
      const key = `${phaseIndex}-${categoryIndex}-${rowIndex}`;
      if (this.expandedRows.has(key)) {
        this.expandedRows.delete(key);
      } else {
        this.expandedRows.add(key);
      }
    },
    isCategoryExpanded(phaseIndex, categoryIndex) {
      return this.expandedCategories.has(`${phaseIndex}-${categoryIndex}`);
    },
    isPhaseExpanded(phaseIndex) {
      return this.expandedPhases.has(phaseIndex);
    },
    isRowExpanded(phaseIndex, categoryIndex, rowIndex) {
      return this.expandedRows.has(
        `${phaseIndex}-${categoryIndex}-${rowIndex}`
      );
    },

    emitToggleSort(column, phaseIndex, categoryIndex, sortType) {
      this.$emit("toggleSort", column, phaseIndex, categoryIndex, sortType);
    },
    getParentFormattedDate(date) {
      return this.$parent.getFormattedDate(date);
    },
    phaseDirection() {
      return this.$parent.phaseDirection();
    },
    categoryDirection() {
      return this.$parent.categoryDirection();
    },
  },
};
</script>

<style scoped>
.pointer {
  margin-right: 7px;
}
.table th {
  border-bottom: 1px solid #eaeaea;
}

.table th,
.table td {
  border-top: none;
  min-width: 50px;
}

.table thead th {
  background: none;
}
.blue {
  color: #0071ce;
}
.img {
  margin-top: -2px;
  width: 12px;
  height: 12px;
}
.table {
  table-layout: fixed;
}
</style>
