<template>
  <div>
    <div :class="{ expanded: isExpanded }" class="expanded-div">
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 10px 20px 5px 20px;
          width: 100%;
          border-bottom: 1px solid lightgray;
          align-items: center;
        "
      >
        <div
          style="display: flex; gap: 10px; cursor: pointer"
          @click="toggleExpand()"
        >
          <h6>
            {{ expandAll ? $t("Collapse") : $t("Expand") }}
          </h6>
          <i
            :class="{
              'fa fa-caret-up': expandAll,
              'fa fa-caret-down': !expandAll,
            }"
            style="font-size: 20px"
          ></i>
        </div>
        <div style="gap: 20px; display: flex; cursor: pointer">
          <i
            class="fa fa-eraser"
            @click="clearAllSelected()"
            data-testid="removeSelectionIcon"
          ></i>
          <i
            class="fa fa-rotate-left"
            style="cursor: pointer"
            @click="resetView()"
          ></i>
        </div>
      </div>

      <div class="team-list-tree" v-if="!isLoadingRibbonData">
        <div
          v-for="organizationLevel in organizationLevels"
          :key="organizationLevel.id"
        >
          <TeamListTree
            :node="organizationLevel"
            :depth="0"
            :neededOrganization="neededOrganization"
            :orgMembers="orgMembers"
            :expandedTeams="expandedTeams"
            :expandedProjectLevels="expandedProjectLevels"
            :shownLevelMembers="shownLevelMembers"
            :shownProjectLevelMembers="shownProjectLevelMembers"
            :selectedMembers="selectedMembers"
            :selectedLevels="selectedLevels"
            :membersAvatars="membersAvatars"
          />
        </div>
        <div
          v-if="allActions.length"
          style="display: flex; justify-content: center; margin: 20px 0"
        >
          <hr
            v-if="!isLoadingRibbonData"
            style="background: white; width: 80%"
          />
        </div>

        <h3
          v-if="!isLoadingRibbonData && allActions.length"
          style="color: black; margin: 20px"
        >
          {{ $t("Project Levels") }}
        </h3>
        <div v-for="projectLevel in projectLevels" :key="projectLevel.id">
          <TeamListTree
            :node="projectLevel"
            :depth="0"
            :neededOrganization="neededOrganization"
            :orgMembers="orgMembers"
            :expandedTeams="expandedTeams"
            :expandedProjectLevels="expandedProjectLevels"
            :shownLevelMembers="shownLevelMembers"
            :shownProjectLevelMembers="shownProjectLevelMembers"
            :selectedMembers="selectedMembers"
            :selectedLevels="selectedLevels"
            :membersAvatars="membersAvatars"
          />
        </div>
      </div>
      <div v-else style="color: white"></div>
    </div>
  </div>
</template>
<script>
import TeamListTree from "./TeamListTree.vue";
import { ContentLoader } from "vue-content-loader";
import { useUserStore } from "@/stores/UserStore";

import { mapState } from "vuex";

export default {
  name: "TeamList",
  data() {
    return {
      userStore: useUserStore(),

      expandAll: false,
      expandedLevels: new Set(),
    };
  },
  components: {
    TeamListTree,
    ContentLoader,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
      selectedJob: (state) => state.selectedJob,
    }),
    projectLevels() {
      return this.neededOrganization.filter((level) => level.isProjectLevel);
    },
    organizationLevels() {
      return this.neededOrganization.filter((level) => !level.isProjectLevel);
    },
  },
  watch: {
    neededOrganization() {
      if (!this.noReset) {
        // this.getActiveUserActions();
        this.neededOrganization.forEach((rootNode) => {
          this.searchAndExpand(rootNode, []);
        });
      }
      this.$parent.stopSelection();
    },
  },
  props: {
    isExpanded: Boolean,
    allActions: Array,
    currentView: String,
    teamMembers: Array,
    meDetails: Object,
    isLoadingRibbonData: Boolean,
    neededOrganization: Array,
    isCountryExpanded: Boolean,
    isDirectionExpanded: Boolean,
    isJobExpanded: Boolean,
    expandedTeams: Set,
    expandedProjectLevels: Set,
    shownLevelMembers: Set,
    shownProjectLevelMembers: Set,
    currentUser: Object,
    orgMembers: Array,
    selectedMembers: Object,
    selectedLevels: Object,
    initialMembers: Set,
    membersAvatars: Array,
    noReset: Boolean,
  },
  mounted() {
    this.expandedTeams.size > 0
      ? (this.expandAll = false)
      : (this.expandAll = true);
  },
  methods: {
    getActiveUserActions() {
      let neededOrganizationLevels = this.organizationLevels.filter((el) =>
        el.members.find(
          (el) =>
            el.email == this.userData.email &&
            el.job_description == this.selectedJob[0]
        )
      );
      this.$parent.clearAllSelected();
      for (let index = 0; index < neededOrganizationLevels.length; index++) {
        this.searchAndExpand(neededOrganizationLevels[index], []);
      }
    },
    clearAllSelected() {
      this.$parent.clearAllSelected();
    },
    resetView() {
      this.$parent.selectedMembers = new Set(this.$parent.initialMembers);
      this.$parent.selectedLevels.clear();
      this.$parent.listActionsForSelectedMembers();
    },
    memberSelected(member, levelName, isInitialMember) {
      this.$parent.memberSelected(member, levelName, isInitialMember);
    },
    memberDeselected(member, levelName) {
      this.$parent.memberDeselected(member, levelName);
    },
    levelSelected(level) {
      this.$parent.levelSelected(level);
    },
    levelDeselected(level) {
      this.$parent.levelDeselected(level);
    },
    searchAndExpand(node, path) {
      if (
        node.members.some((member) => {
          return (
            member.email === this.userData.email &&
            member.job_description == this.selectedJob[0] &&
            node.level_name == this.userStore.currentLevel
          );
        })
      ) {
        const foundMember = node.members.find(
          (member) => member.email === this.userData.email
        );
        this.$parent.memberSelected(foundMember, node.level_name, true);

        [...path, node.level_name].forEach((level) => {
          if (node.isProjectLevel) {
            this.expandedProjectLevels.add(level);
          } else {
            this.expandedTeams.add(level);
          }
        });
        if (node.isProjectLevel) {
          this.shownProjectLevelMembers.add(node.level_name);
        } else {
          this.shownLevelMembers.add(node.level_name);
        }
      }

      // Continue to search in children
      if (node.children && node.children.length > 0) {
        node.children.forEach((child) => {
          this.searchAndExpand(child, [...path, node.level_name]);
        });
      }
    },
    switchView(view) {
      this.$emit("switch-view", view);
    },

    handleMemmberClick(member) {
      this.$parent.memberSelected(member);
      this.$emit("filter-by-member", member);
    },
    getInitials(member) {
      const [firstName, lastName] = member.split(" ");
      const initials =
        (firstName ? firstName[0] : "") + (lastName ? lastName[0] : "");
      return initials.toUpperCase();
    },
    toggleCountry(country) {
      this.$parent.toggleCountry(country);
    },
    toggleDirection(country, direction) {
      this.$parent.toggleDirection(country, direction);
    },
    toggleJob(country, direction, job) {
      this.$parent.toggleJob(country, direction, job);
    },
    toggleExpand() {
      if (!this.expandAll) {
        const addToExpandedTeams = (node) => {
          node.forEach((level) => {
            if (level.isProjectLevel) {
              this.expandedProjectLevels.add(level.level_name);
              this.shownProjectLevelMembers.add(level.level_name);
            } else {
              this.expandedTeams.add(level.level_name);
              this.shownLevelMembers.add(level.level_name);
            }
            if (level.children.length > 0) {
              addToExpandedTeams(level.children);
            }
          });
        };
        addToExpandedTeams(this.neededOrganization);
        this.expandAll = true;
      } else {
        this.expandedTeams.clear();
        this.shownLevelMembers.clear();
        this.expandedProjectLevels.clear();
        this.shownProjectLevelMembers.clear();

        this.expandAll = false;
      }
    },
  },
};
</script>

<style scoped>
.selected-member {
  background-color: #9ec2f1;
  border-left: 8px solid #0071ce;
}
.expanded-div {
  position: sticky;
  background-color: white;
  width: 280px;
  /* overflow: auto; */
  height: 85vh;
  border: 1px solid lightgray;
}
.team-list-tree {
  margin-top: 10px;
  max-height: 90%;
  color: black;
  overflow: auto;
}
@media (max-width: 1096px) {
  .expanded-div {
    border-radius: 0 0 0 0;
    width: 280px;
    height: 600px;
  }
  .team-list-tree {
    max-height: 80%;
  }
}
@media (max-width: 970px) {
  .expanded-div {
    width: 100%;
  }
  .team-list-tree {
    max-height: 100%;
  }
}
.member-btn {
  border-radius: 24px;
  border: 1px solid;
}
</style>
