<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center" size="lg">
      <template v-slot:header>
        <h3>Change order</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div class="modal-body change-order-modal">
        <transition-group name="list" tag="div" class="list-container">
          <div
            v-if="newArray.length"
            v-for="(phase, phaseIndex) in newArray"
            :key="phaseIndex"
            class="phaseStyles"
          >
            <div class="orderingIcons">
              <svg
                @click="swapPhases(phase, phaseIndex, 'up')"
                class="orderIcon"
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g id="style=linear">
                    <g id="arrow-long-up">
                      <path
                        id="vector"
                        d="M11.9929 21.293L11.9929 2.79297"
                        stroke="#7B7B7B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>
                      <path
                        id="vector_2"
                        d="M4.99292 9.29297L11.2858 3.00008C11.6763 2.60955 12.3095 2.60955 12.7 3.00008L18.9929 9.29297"
                        stroke="#7B7B7B"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
              <svg
                @click="swapPhases(phase, phaseIndex, 'down')"
                class="orderIcon"
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g id="style=stroke">
                    <g id="arrow-long-down">
                      <path
                        id="vector (Stroke)"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.9929 1.95728C12.4071 1.95728 12.7429 2.29306 12.7429 2.70728L12.7429 21.2073C12.7429 21.6215 12.4071 21.9573 11.9929 21.9573C11.5787 21.9573 11.2429 21.6215 11.2429 21.2073L11.2429 2.70728C11.2429 2.29306 11.5787 1.95728 11.9929 1.95728Z"
                        fill="#7B7B7B"
                      ></path>
                      <path
                        id="vector (Stroke)_2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M4.46259 14.1769C4.75548 13.8841 5.23036 13.8841 5.52325 14.1769L11.8161 20.4698C11.9138 20.5675 12.0721 20.5675 12.1697 20.4698L18.4626 14.1769C18.7555 13.8841 19.2304 13.8841 19.5232 14.1769C19.8161 14.4698 19.8161 14.9447 19.5232 15.2376L13.2304 21.5305C12.5469 22.2139 11.4389 22.2139 10.7555 21.5305L4.46259 15.2376C4.1697 14.9447 4.1697 14.4698 4.46259 14.1769Z"
                        fill="#7B7B7B"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div class="phaseDataStyles">
              <div style="background: #e9e9e9; width: 100%; padding-left: 20px">
                <h4 style="margin: 0; padding: 10px">
                  {{ phaseIndex + 1 }} - {{ phase.phase_name }}
                </h4>
              </div>
              <div
                style="width: 100%; padding: 10px 30px"
                v-if="phase.categories.length > 0"
              >
                <div
                  v-for="(category, categoryIndex) in phase.categories"
                  style="display: flex; align-items: baseline; gap: 20px"
                >
                  <div style="display: flex; gap: 5px">
                    <svg
                      @click="
                        swapCategories(
                          category,
                          phaseIndex,
                          categoryIndex,
                          'up'
                        )
                      "
                      class="orderIcon"
                      width="15px"
                      height="15px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g id="style=linear">
                          <g id="arrow-long-up">
                            <path
                              id="vector"
                              d="M11.9929 21.293L11.9929 2.79297"
                              stroke="#7B7B7B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            ></path>
                            <path
                              id="vector_2"
                              d="M4.99292 9.29297L11.2858 3.00008C11.6763 2.60955 12.3095 2.60955 12.7 3.00008L18.9929 9.29297"
                              stroke="#7B7B7B"
                              stroke-width="1.5"
                              stroke-linecap="round"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <svg
                      @click="
                        swapCategories(
                          category,
                          phaseIndex,
                          categoryIndex,
                          'down'
                        )
                      "
                      class="orderIcon"
                      width="15px"
                      height="15px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        <g id="style=stroke">
                          <g id="arrow-long-down">
                            <path
                              id="vector (Stroke)"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.9929 1.95728C12.4071 1.95728 12.7429 2.29306 12.7429 2.70728L12.7429 21.2073C12.7429 21.6215 12.4071 21.9573 11.9929 21.9573C11.5787 21.9573 11.2429 21.6215 11.2429 21.2073L11.2429 2.70728C11.2429 2.29306 11.5787 1.95728 11.9929 1.95728Z"
                              fill="#7B7B7B"
                            ></path>
                            <path
                              id="vector (Stroke)_2"
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.46259 14.1769C4.75548 13.8841 5.23036 13.8841 5.52325 14.1769L11.8161 20.4698C11.9138 20.5675 12.0721 20.5675 12.1697 20.4698L18.4626 14.1769C18.7555 13.8841 19.2304 13.8841 19.5232 14.1769C19.8161 14.4698 19.8161 14.9447 19.5232 15.2376L13.2304 21.5305C12.5469 22.2139 11.4389 22.2139 10.7555 21.5305L4.46259 15.2376C4.1697 14.9447 4.1697 14.4698 4.46259 14.1769Z"
                              fill="#7B7B7B"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div>
                    <h6 style="margin: 0; padding: 0">
                      {{ phaseIndex + 1 }}.{{ categoryIndex + 1 }} -
                      {{ category.category_name }}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">Cancel</CButton>
        <CButton @click="saveOrder" color="primary" class="modal-confirm">
          Save
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ProjectsService } from "@/services/projects.js";
import { mapState } from "vuex";

export default {
  name: "OrderModal",
  data() {
    return {
      newArray: [],
    };
  },
  props: {
    show: Boolean,
    tableItems: Array,
    allActions: Array,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    simplifiedTableItems() {
      return this.newArray.map((phase) => {
        const simplifiedPhase = {
          phase_name: phase.phase_name,
          categories: [],
        };

        phase.categories.forEach((category) => {
          simplifiedPhase.categories.push({
            category_name: category.category_name,
          });
        });

        return simplifiedPhase;
      });
    },
  },
  watch: {
    show: {
      handler: function (val, oldVal) {
        if (val) {
          this.getSimplifiedTableItems();
        }
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    phaseDirection(phaseIndex) {
      return this.$parent.phaseDirection(phaseIndex);
    },
    categoryDirection(categoryIndex) {
      return this.$parent.categoryDirection(categoryIndex);
    },
    isPhaseExpanded(phaseIndex) {
      return this.$parent.isPhaseExpanded(phaseIndex);
    },
    isCategoryExpanded(phaseIndex, categoryIndex) {
      return this.$parent.isCategoryExpanded(phaseIndex, categoryIndex);
    },
    getFormattedDate(date) {
      return this.$parent.getFormattedDate(date);
    },
    togglePhase(phaseIndex) {
      this.$parent.togglePhase(phaseIndex);
    },
    toggleCategory(phaseIndex, categoryIndex) {
      this.$parent.toggleCategory(phaseIndex, categoryIndex);
    },
    swapPhases(phase, phaseIndex, direction) {
      const currentIndex = this.newArray.findIndex((item) => item === phase);

      if (currentIndex !== -1) {
        let newIndex;
        if (direction === "down" && currentIndex < this.newArray.length - 1) {
          newIndex = currentIndex + 1;
        } else if (direction === "up" && currentIndex > 0) {
          newIndex = currentIndex - 1;
        } else {
          return;
        }

        [this.newArray[currentIndex], this.newArray[newIndex]] = [
          this.newArray[newIndex],
          this.newArray[currentIndex],
        ];
      } else {
      }
    },
    swapCategories(category, phaseIndex, categoryIndex, direction) {
      const categoriesArray = this.newArray[phaseIndex].categories;

      if (
        categoriesArray &&
        categoryIndex >= 0 &&
        categoryIndex < categoriesArray.length
      ) {
        let newCategoryIndex;
        if (
          direction === "down" &&
          categoryIndex < categoriesArray.length - 1
        ) {
          newCategoryIndex = categoryIndex + 1;
        } else if (direction === "up" && categoryIndex > 0) {
          newCategoryIndex = categoryIndex - 1;
        } else {
          return;
        }

        [categoriesArray[categoryIndex], categoriesArray[newCategoryIndex]] = [
          categoriesArray[newCategoryIndex],
          categoriesArray[categoryIndex],
        ];
      } else {
      }
    },
    toggleSort(sortType, phaseIndex, categoryIndex, sortDirection) {
      this.$parent.toggleSort(
        sortType,
        phaseIndex,
        categoryIndex,
        sortDirection
      );
    },
    getSimplifiedTableItems() {
      this.newArray = this.allActions.map((phase) => {
        const simplifiedPhase = { ...phase };
        delete simplifiedPhase.actions;
        if (simplifiedPhase.categories) {
          simplifiedPhase.categories = [...simplifiedPhase.categories];
        }

        return simplifiedPhase;
      });
    },
    async saveOrder() {
      const payload = {
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        structure: this.simplifiedTableItems,
      };
      const { data, res } = await ProjectsService.save_phases_categories_order(
        payload
      );
      if (res.status === 200) {
        this.close();
        await this.$parent.orderChanged(this.simplifiedTableItems);
      }
    },
  },
};
</script>

<style scoped>
.pointer:hover {
  color: gray;
}
.modal-body.change-order-modal {
  max-height: 520px;
  overflow-y: auto;
}
.phaseStyles {
  margin-bottom: 10px;
  border: 1px solid rgb(195, 195, 195);
  display: flex;
  width: 100%;
}
.orderingIcons {
  border-right: 1px solid rgb(195, 195, 195);
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.phaseDataStyles {
  width: 100%;
}
.orderIcon {
  cursor: pointer;
}
.orderIcon:hover {
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter, .list-leave-to /* .list-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
</style>
