<template>
  <div>
    <CModal
      :show="showIndividualAction"
      @hide="closeIndividualAction"
      :draggable="false"
    >
      <template v-slot:header>
        <div
          class="d-flex"
          style="
            padding-left: 1rem;
            justify-content: space-between;
            align-items: center;
            width: 100%;
          "
        >
          <h3>{{ $t("Add Action") }}</h3>
          <CButton @click="closeIndividualAction">
            <i class="fa fa-times"></i>
          </CButton>
        </div>
      </template>
      <div class="modal-body addaction" style="margin-bottom: 3px">
        <div
          v-if="userCanAddActionForAll || userCanAddTeamActionOnly"
          style="
            margin-top: 10px;
            width: 100%;
            background: #f7fafe;
            border: 1px solid #9ec2f1;
            padding: 20px;
            margin-bottom: 10px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
              "
            >
              <label
                class="filter_btn"
                :class="{ selected: selectedFilter === 'users' }"
                @click="filterByUsers"
                >{{ $t("Users") }}</label
              >
              <label
                v-if="userCanAddActionForAll || userCanAddTeamActionOnly"
                class="filter_btn"
                :class="{ selected: selectedFilter === 'levels' }"
                @click="filterByLevels"
                >{{ $t("Levels") }}</label
              >
              <label
                v-if="userCanAddActionForAll || userCanAddTeamActionOnly"
                class="filter_btn"
                :class="{ selected: selectedFilter === 'roles' }"
                @click="filterByRole"
                >{{ $t("Roles") }}</label
              >
            </div>
            <el-checkbox
              :disabled="userCanAddActionForAll && userCanAddTeamActionOnly"
              v-model="selectAll"
              :label="$t('Select All')"
              size="large"
            />
          </div>
          <div v-if="selectedFilter === 'users'">
            <label for="current users">{{ $t("Current Users") }}</label>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedCurrentUsers"
                :data="scopeSelectedUsersOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Current Selection')"
              />
            </div>
          </div>
          <div v-else-if="selectedFilter === 'levels'">
            <label for="current levels">{{ $t("Current Levels") }}</label>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedCurrentLevels"
                :data="scopeSelectedLevelOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Current Selection')"
              />
            </div>
          </div>
          <div v-else-if="selectedFilter === 'roles'">
            <label for="roles">{{ $t("Roles") }}</label>
            <el-tree-select
              filterable
              style="width: 100%"
              v-model="selectedRoles"
              :data="globalStore.scopeRolesOptions"
              multiple
              :render-after-expand="false"
              show-checkbox
              check-strictly
              check-on-click-node
              :placeholder="$t('Select Role')"
              :disabled="selectAll"
            />
          </div>

          <div
            style="margin-top: 10px; width: 100%"
            v-if="selectedFilter === 'users'"
          >
            <div>
              <label for="users">{{ $t("Other selections") }}</label>
            </div>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedOtherUsers"
                :data="scopeOtherUsersOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Other selections')"
              />
            </div>
          </div>
          <div
            style="margin-top: 10px; width: 100%"
            v-if="selectedFilter === 'levels'"
          >
            <div>
              <label for="levels">{{ $t("Other selections") }}</label>
            </div>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedOtherLevels"
                :data="scopeOtherLevelsOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Other selections')"
              />
            </div>
          </div>
        </div>
        <div
          v-else
          style="
            margin-top: 10px;
            width: 100%;
            background: #f7fafe;
            border: 1px solid #9ec2f1;
            padding: 20px;
            margin-bottom: 10px;
          "
        >
          <label for="current users">{{ $t("Current users") }}</label>
          <div>
            <el-tree-select
              filterable
              style="width: 100%"
              v-model="selectedCurrentUsers"
              :data="scopeSelectedUsersOptions"
              multiple
              :render-after-expand="false"
              show-checkbox
              check-strictly
              check-on-click-node
              :disabled="true"
              :placeholder="$t('Current Selection')"
            />
          </div>
        </div>
        <div class="d-flex" style="justify-content: space-between">
          <div style="width: 49%">
            <label for="phase">{{ $t("Phase") }}</label>
            <el-select
              class="style-chooser"
              v-model="phase"
              filterable
              allow-create
              label="text"
              track-by="value"
              :placeholder="$t('Select or Enter a phase')"
            >
              <el-option
                v-for="item in phaseOptions.map((el) => ({
                  value: el,
                  label: el,
                  id: el,
                }))"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            /></el-select>
          </div>
          <div style="width: 49%">
            <label for="category">{{ $t("Category") }}</label>
            <el-select
              class="style-chooser"
              v-model="category"
              filterable
              allow-create
              label="text"
              track-by="value"
              :placeholder="$t('Select or Enter a category')"
              :disabled="!phase"
            >
              <el-option
                v-for="item in categoryOptions.map((el) => ({
                  value: el.code,
                  label: el.code,
                  id: el.code,
                }))"
                :key="item.value"
                :label="item.value"
                :value="item.value"
            /></el-select>
          </div>
        </div>

        <div style="margin-top: 10px">
          <label for="actionTitle">{{ $t("Action Title") }}</label>
          <!-- <CInput v-model="actionTitle" /> -->
          <el-input
            id="actionTitle"
            v-model="actionTitle"
            :placeholder="$t('Action Title')"
          />
        </div>
        <div style="margin-top: 10px">
          <label for="subjectText">{{ $t("Action description") }}</label>
          <!-- <CTextarea
            id="subjectText"
            style="height: 80px"
            v-model="subjectText"
          /> -->
          <el-input
            id="subjectText"
            v-model="subjectText"
            style="height: 80px"
            :rows="4"
            type="textarea"
            :placeholder="$t('Action description')"
          />
        </div>
        <div
          class="d-flex"
          style="
            margin-top: 20px;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="flex: 1">
            <label for="dueDate">{{ $t("Due date") }}</label>
            <!-- <CInput id="dueDate" type="date" v-model="dueDate" /> -->
            <el-date-picker
              v-model="dueDate"
              id="dueDate"
              type="date"
              placeholder="Due Date"
              :size="size"
              format="DD/MM/YYYY"
            />
          </div>
          <div style="flex: 1; margin-left: 10px">
            <div>
              <label for="points">{{ $t("Points") }}</label>
            </div>
            <!-- <CInput
              id="points"
              :modelValue="points"
              @update:modelValue="points = $event"
              type="number"
              max="1000"
              min="0"
            /> -->
            <div>
              <el-input-number
                id="points"
                v-model="points"
                :min="1"
                :max="1000"
                @change="points = $event"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
        <div class="mt-2">
          <label for="progress">{{ $t("Progress") }}</label>
          <!-- <CInput
            id="progress"
            :modelValue="progress"
            @update:modelValue="progress = $event"
            @input="handleProgressInput($event)"
            type="number"
            max="100"
            min="0"
          /> -->
          <el-input-number
            id="progress"
            v-model="progress"
            :min="1"
            :max="100"
            @update:modelValue="progress = $event"
            @change="handleProgressInput($event)"
            style="width: 100%"
          />
        </div>
      </div>
      <template v-slot:footer>
        <div>
          <CButton @click="closeIndividualAction" class="link">{{
            $t("Cancel")
          }}</CButton>
          <CButton
            @click="addAction"
            color="primary"
            class="modal-confirm"
            :disabled="isConfirmDisabled"
          >
            {{ $t("Confirm") }}
          </CButton>
        </div>
      </template>
    </CModal>
    <CModal :show="showMultipleActions" :draggable="false">
      <FileDragDrop
        data-testid="multipleActionsInput"
        title="Add actions"
        label=""
        @upload="(uploadFiles) => handleUpload(uploadFiles, 1)"
      />
      <template v-slot:header>
        <h3>{{ $t("Add multiple action") }}</h3>
        <CButton @click="closeMultipleActions">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <template v-slot:footer>
        <CButton @click="closeMultipleActions" color="link">{{
          $t("close")
        }}</CButton>
        <!-- <CButton color="primary" class="add-btn" @click="addMultipleActions"
          >Add actions</CButton
        > -->
      </template>
    </CModal>
  </div>
</template>
<script>
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import ScopeSelections from "@/components/ScopeSelections.vue";
import { mapState } from "vuex";
import { useGlobalStore } from "@/stores/store";

export default {
  name: "ActionsModals",
  components: {
    vSelect,
    Treeselect,
    ScopeSelections,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
      expandedLevels: (state) => state.expandedLevels,
      selectedLevels: (state) => state.selectedLevels,
    }),

    isConfirmDisabled() {
      const areSingleValuesEmpty = !(
        this.phase.trim() &&
        this.category.trim() &&
        this.actionTitle.trim() &&
        this.subjectText.trim() &&
        this.dueDate &&
        this.points
      );

      const areArrayFieldsEmpty =
        !this.selectAll &&
        (!this.selectedCurrentUsers ||
          this.selectedCurrentUsers.length === 0) &&
        (!this.selectedCurrentLevels ||
          this.selectedCurrentLevels.length === 0) &&
        (!this.selectedRoles || this.selectedRoles.length === 0) &&
        (!this.selectedOtherUsers || this.selectedOtherUsers.length === 0) &&
        (!this.selectedOtherLevels || this.selectedOtherLevels.length === 0);

      return areSingleValuesEmpty || areArrayFieldsEmpty;
    },
  },
  props: {
    showIndividualAction: Boolean,
    categoryOptions: Array,
    showMultipleActions: Boolean,
    phaseOptions: Array,
    orgMembers: Array,
    selectedLevels: Object,
    usersModal: Array,
    selectedMembers: Set,
    preSelectedUsers: Array,
    otherUsers: Array,
    preSelectedLevels: Array,
    otherLevels: Array,
    selectedRoles: Array,
    scopeSelectedUsersOptions: Array,
    scopeOtherUsersOptions: Array,
    scopeSelectedLevelOptions: Array,
    scopeOtherLevelsOptions: Array,
    userCanAddActionForAll: Boolean,
    userCanAddTeamActionOnly: Boolean,
    userCanAddOwnActionOnly: Boolean,
  },
  data() {
    return {
      globalStore: useGlobalStore(),
      optionsKey: 0,
      phase: "",
      category: "",
      role: "",
      actionTitle: "",
      subjectText: "",
      dueDate: "",
      points: 100,
      progress: 0,
      enableCategoryDropDown: false,
      selectedCurrentUsers: null,
      selectedOtherUsers: null,
      selectedCurrentLevels: null,
      selectedOtherLevels: null,
      selectedRoles: [],
      selectedFilter: "users",
      selectAll: false,
    };
  },

  watch: {
    phase(val) {
      this.$emit("update-phase", val);
      this.enableCategoryDropDown = true;
    },
    category(val) {
      this.$emit("update-category", val);
    },
    role(val) {
      this.$emit("update-role", val);
    },
    // selectedUsers(val) {
    //   this.$emit("update-users", val);
    // },
    actionTitle(val) {
      this.$emit("update-action-title", val);
    },
    subjectText(val) {
      this.$emit("update-subject-text", val);
    },
    dueDate(val) {
      this.$emit("update-due-date", val);
    },
    points(val) {
      this.$emit("update-points", val);
    },
    progress(val) {
      this.$emit("update-progress", val);
    },
    orgMembers: {
      handler() {
        this.optionsKey++; // Increment the key to force re-render
      },
      deep: true,
    },
    showIndividualAction(val) {
      if (val) {
        this.selectedCurrentUsers = this.preSelectedUsers;
        this.selectedOtherUsers = this.otherUsers;
        this.selectedCurrentLevels = this.preSelectedLevels;
        this.selectedOtherLevels = this.otherLevels;
      }
    },
  },
  methods: {
    getAllUsers() {
      const allUsers = this.orgMembers.flatMap((member) => {
        return member.roles.map((role) => {
          return {
            value: `${member.email} (${role.level_name} - ${role.job_description})`,
            label: `${member.first_name} ${member.last_name} (${role.level_name} - ${role.job_description})`,
          };
        });
      });
      const usersMatch = this.usersModal.flatMap((member) => {
        return member.org_roles.map((role) => {
          return {
            value: `${member.email} (${role.level_name} - ${role.job_description})`,
            label: `${member.firstname} ${member.lastname} (${role.level_name} - ${role.job_description})`,
          };
        });
      });

      let uniqueOptions = new Map();

      usersMatch.forEach((userMatch) => {
        if (!uniqueOptions.has(userMatch.value)) {
          uniqueOptions.set(userMatch.value, userMatch);
        }
      });

      this.membersOptions = Array.from(uniqueOptions.values());

      this.membersOptions.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    },
    getUsersByDefault() {
      const usersMatch = this.usersModal.flatMap((member) => {
        return member.org_roles.map((role) => {
          return {
            value: `${member.email} (${role.level_name} - ${role.job_description})`,
            label: `${member.firstname} ${member.lastname} (${role.level_name} - ${role.job_description})`,
          };
        });
      });
      const emailSet = new Set();
      const expandedLevelsIterator = this.expandedLevels.values();
      const rootLevel = expandedLevelsIterator.next().value;
      this.collectEmails(rootLevel, emailSet);
      const currentUserSet = new Set();
      this.currentUser = this.transformSetToArray(emailSet).filter((user) => {
        const found = usersMatch.find((match) => match.id.includes(user.id));
        if (found) {
          currentUserSet.add(found);
          return true;
        }
        return false;
      });

      this.membersOptions = usersMatch.filter(
        (member) => !currentUserSet.has(member)
      );
      this.currentUser.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
      this.membersOptions.sort((a, b) =>
        a.label.toLowerCase().localeCompare(b.label.toLowerCase())
      );
    },
    collectEmails(node, emailSet) {
      if (node.members) {
        node.members.forEach((member) => {
          const key = {
            value: `${member.email} (${node.level_name} - ${member.job_description})`,
            label: `${member.firstname} ${member.lastname} (${node.level_name} - ${member.job_description})`,
          };
          emailSet.add(key);
        });
      }
      if (node.children) {
        node.children.forEach((child) => this.collectEmails(child, emailSet));
      }
    },
    transformSetToArray(inputSet) {
      // Convert the set into an array of objects with id and label
      const resultArray = Array.from(inputSet);
      return resultArray;
    },
    filterByUsers() {
      this.selectedFilter = "users";
    },
    filterByLevels() {
      this.selectedFilter = "levels";
    },
    filterByRole() {
      this.selectedFilter = "roles";
    },
    closeIndividualAction() {
      this.phase = "";
      this.category = "";
      this.role = "";
      // this.selectedUsers = [];
      this.actionTitle = "";
      this.subjectText = "";
      this.dueDate = "";
      this.points = 100;
      this.$parent.testKeyIncrease();
      this.$emit("close-individual-action");
    },
    addAction() {
      const allUsers = this.selectedCurrentUsers.concat(
        this.selectedOtherUsers
      );
      const allLevels = (this.selectedCurrentLevels || []).concat(
        this.selectedOtherLevels || []
      );

      // Map allUsers to create objects with email, level_name, and job_description
      const userObjects = allUsers.map((user) => {
        const email = user.match(/([^@]+@\w+\.\w+)/)[0];
        const details = user.split(" (")[1].replace(")", "").split(" - ");
        const level_name = details[0];
        const job_description = details[1];
        return { email, level_name, job_description };
      });

      this.$parent.addAction({
        phase: this.phase,
        category: this.category,
        users: userObjects,
        levels: allLevels,
        roles: this.selectedRoles,
        action: this.actionTitle,
        subject: this.subjectText,
        dueDate: this.dueDate,
        award: this.points,
        progress: this.progress,
      });

      this.closeIndividualAction();
    },

    closeMultipleActions() {
      this.$emit("close-multiple-actions");
    },
    handleUpload(uploadFile, fileId) {
      this.$emit("upload-files", uploadFile, fileId);
    },
    addMultipleActions() {
      this.$emit("add-multiple-actions");
    },
    addTag(option, tag) {
      const exists = option.find((opt) => opt.value === tag);
      if (!exists) {
        option.push({ code: tag, value: tag });
      }
    },
    handleProgressInput(event) {
      // let inputValue = event;
      // inputValue = parseInt(inputValue, 10);
      // if (inputValue > 100) {
      //   this.progress = 100;
      // } else if (inputValue < 0) {
      //   this.progress = 0;
      // } else {
      //   this.progress = inputValue;
      // }
      this.progress = event;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em;
  border-bottom: 2px solid #0071ce;
}

:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
}
.style-chooser .vs__selected-options {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-body.addaction {
  max-height: 520px !important;
}

.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 97px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}

::v-deep .modal-footer {
  padding: 0.75rem 38px !important;
}

.modal-dialog {
  top: unset !important;
  left: unset !important;
}
</style>
