<template>
  <div
    :class="{
      selectedLevel:
        Array.from(selectedLevels)[0]?.level_name === node.level_name,
      regularLevel: hoverRegularLevel,
    }"
  >
    <div style="display: flex; align-items: center; gap: 10px">
      <div :style="computedStyle">
        <div
          :style="computedStyle"
          @click="toggleLevel()"
          :ref="`level-${node.level_name}`"
        >
          <i
            :class="isExpanded ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"
            style="cursor: pointer"
          ></i>
          <h4
            @mouseenter="onH4Hover"
            @mouseleave="onH4Leave"
            style="width: 100%"
            :style="{ fontSize: fontSize.fontSize }"
            :class="{
              'bold-text': node.parent_id === '',
              'regular-text': node.parent_id !== '',
            }"
          >
            {{ node.level_name }}
          </h4>
        </div>
        <div v-if="isExpanded" style="margin-right: 10px">
          <i class="fa fa-users level-icons" @click="viewUsersOfLevel()"></i>
        </div>
      </div>
    </div>
    <div v-if="isExpanded">
      <div v-if="isMembersShown">
        <div
          v-for="member in getSortedMembers(node.members)"
          :key="member.email"
          :class="{
            selectedMember: selectedMembers.size > 0 && hasIt(member, node),
            regularmember:
              !selectedMembers ||
              !selectedMembers.has(
                `${member.email} - ${node.level_name} - ${member.job_description}`
              ),
          }"
          data-testid="selectMember"
          @click="memberSelected(member, node.level_name, false)"
        >
          <div>
            <div style="display: flex; gap: 20px; align-items: center">
              <div>
                <div
                  :class="{
                    'admin-member': member.admin,
                    'responsible-member': member.responsible,
                    'regular-member': !member.admin && !member.responsible,
                  }"
                  style="
                    width: 45px;
                    background: white;
                    height: 45px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <div
                    v-if="emailIsInAvatarList(member.email)"
                    class="avatar_ribbon"
                  >
                    <DefaultAvatarHome
                      :primaryColor="findAvatarByEmail(member.email).skinTone"
                      :selectedHair="
                        findAvatarByEmail(member.email).selectedHair
                      "
                      :topClothe="
                        findAvatarByEmail(member.email).selectedTopClothes
                      "
                      :bottomClothe="
                        findAvatarByEmail(member.email).selectedBottomClothes
                      "
                      :facialHair="
                        findAvatarByEmail(member.email).selectedFacialHair
                      "
                      :facialExpression="
                        findAvatarByEmail(member.email).selectedExpression
                      "
                      :facialHairPosition="facialHairPosition"
                      :shoes="findAvatarByEmail(member.email).selectedShoes"
                      :topClothesPosition="topClothesPosition"
                      :bottomClothesPosition="bottomClothesPosition"
                    />
                  </div>
                  <div v-else style="color: black; font-weight: bold">
                    {{
                      (member.firstname[0] || "") + (member.lastname[0] || "")
                    }}
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <p
                    style="
                      font-size: 12px;
                      margin: 0;
                      padding: 0;
                      font-weight: bold;
                      color: black !important;
                    "
                  >
                    {{ member.firstname }} {{ member.lastname }}
                    <!-- {{ member }} -->
                  </p>
                  <p
                    style="
                      margin: 0;
                      padding: 0;
                      font-size: 12px;
                      color: black !important;
                    "
                  >
                    {{ $t(member.job_description) }}
                  </p>
                  <p
                    v-if="member.expertise"
                    style="
                      margin: 0;
                      padding: 0;
                      font-size: 12px;
                      color: black !important;
                    "
                  >
                    {{ $t(member.expertise) }}
                  </p>
                </div>

                <div style="display: flex; flex-wrap: no-wrap">
                  <CProgress
                    :value="member.avgAdvancement"
                    style="height: 5px; width: 120px"
                    class="mt-2"
                  >
                    <CProgressBar
                      :value="member.avgAdvancement"
                      style="background: #3399ff"
                    ></CProgressBar>
                  </CProgress>
                  <span
                    class="ml-2"
                    style="font-size: 12px; color: #3399ff; font-weight: bold"
                  >
                    {{ formatAdvancement(member.avgAdvancement) }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hasChildren && isExpanded">
      <TeamListTree
        v-for="child in node.children"
        :key="child.id"
        :node="child"
        :depth="depth + 1"
        :orgViewData="orgViewData"
        :neededOrganization="neededOrganization"
        :orgMembers="orgMembers"
        :expandedTeams="expandedTeams"
        :expandedProjectLevels="expandedProjectLevels"
        :shownLevelMembers="shownLevelMembers"
        :shownProjectLevelMembers="shownProjectLevelMembers"
        :selectedMembers="selectedMembers"
        :selectedLevels="selectedLevels"
        :membersAvatars="membersAvatars"
      />
    </div>
  </div>
</template>

<script>
import DefaultAvatarHome from "../../home/components/DefaultAvatarHome.vue";
import { mapState } from "vuex";

export default {
  name: "TeamListTree",
  components: { DefaultAvatarHome },

  props: {
    node: Object,
    depth: Number,
    neededOrganization: Object,
    orgMembers: Array,
    expandedTeams: Set,
    expandedProjectLevels: Set,
    shownLevelMembers: Set,
    shownProjectLevelMembers: Set,
    selectedMembers: Set,
    selectedLevels: Set,
    membersAvatars: Array,
  },
  data() {
    return {
      shownUsers: [],
      hoverRegularLevel: false,
      topClothesPosition: "108px",
      bottomClothesPosition: "23px",
    };
  },
  mounted() {},
  watch: {},
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
      expandedLevelName: (state) => state.expandedLevelName,
    }),
    computedStyle() {
      return {
        paddingLeft: "10px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        cursor: "pointer",
        width: "100%",
        marginLeft: this.fontSize.marginLeft,
      };
    },
    hasChildren() {
      return this.node.children && this.node.children.length > 0;
    },
    fontSize() {
      const baseSize = 14;
      const decreaseFactor = 2;
      const minSize = 10;

      let calculatedFontSize = baseSize - this.depth * decreaseFactor;
      calculatedFontSize = Math.max(calculatedFontSize, minSize);

      return {
        fontSize: calculatedFontSize + "px",
      };
    },

    isExpanded() {
      if (this.node.isProjectLevel) {
        return this.expandedProjectLevels.has(this.node.level_name);
      } else {
        return this.expandedTeams.has(this.node.level_name);
      }
    },
    isMembersShown() {
      if (this.node.isProjectLevel) {
        return this.shownProjectLevelMembers.has(this.node.level_name);
      } else {
        return this.shownLevelMembers.has(this.node.level_name);
      }
      // return this.shownLevelMembers.has(this.node.level_name);
    },
  },
  methods: {
    findAvatarByEmail(email) {
      return this.membersAvatars.find((avatar) => avatar.email === email)
        .avatar_data;
    },
    emailIsInAvatarList(email) {
      return this.membersAvatars.some(
        (memberAvatar) => memberAvatar.email === email
      );
    },

    getSortedMembers(members) {
      return members.sort((a, b) => {
        if (a.admin === b.admin) {
          if (a.responsible === b.responsible) {
            return 0;
          }
          return a.responsible ? -1 : 1;
        }
        return a.admin ? -1 : 1;
      });
    },

    hasIt(member, node) {
      return this.selectedMembers.has(
        `${member.email} - ${node.level_name} - ${member.job_description} - ${member.firstname} ${member.lastname}`
      );
    },
    onH4Hover() {
      if (
        !this.selectedLevel ||
        this.selectedLevel.level_name !== this.node.level_name
      ) {
        this.hoverRegularLevel = true;
      }
    },
    onH4Leave() {
      this.hoverRegularLevel = false;
    },
    formatAdvancement(value) {
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(1);
      }
    },
    viewUsersOfLevel() {
      if (this.node.isProjectLevel) {
        this.shownProjectLevelMembers.has(this.node.level_name)
          ? this.shownProjectLevelMembers.delete(this.node.level_name)
          : this.shownProjectLevelMembers.add(this.node.level_name);
      } else {
        this.shownLevelMembers.has(this.node.level_name)
          ? this.shownLevelMembers.delete(this.node.level_name)
          : this.shownLevelMembers.add(this.node.level_name);
      }
    },
    toggleLevel() {
      if (this.node.isProjectLevel) {
        this.shownProjectLevelMembers.clear();
        if (this.expandedProjectLevels.has(this.node.level_name)) {
          this.expandedProjectLevels.delete(this.node.level_name);
          this.shownProjectLevelMembers.delete(this.node.levelName);
        } else {
          let currentNode = this.node;
          const ancestors = new Set();
          while (currentNode.parent_id) {
            ancestors.add(currentNode.parent_id);
            currentNode = this.findNodeById(
              currentNode.parent_id,
              this.neededOrganization
            );
          }

          this.expandedProjectLevels.forEach((levelName) => {
            const level = this.findNodeByLevelName(
              levelName,
              this.neededOrganization
            );
            if (level && !ancestors.has(level.id)) {
              this.expandedProjectLevels.delete(levelName);
            }
          });
          this.expandedProjectLevels.add(this.node.level_name);
          this.shownProjectLevelMembers.add(this.node.level_name);
          this.levelSelected(this.node);
        }
      } else {
        this.shownLevelMembers.clear();
        if (this.expandedTeams.has(this.node.level_name)) {
          this.expandedTeams.delete(this.node.level_name);
          this.shownLevelMembers.delete(this.node.levelName);
        } else {
          let currentNode = this.node;
          const ancestors = new Set();
          while (currentNode.parent_id) {
            ancestors.add(currentNode.parent_id);
            currentNode = this.findNodeById(
              currentNode.parent_id,
              this.neededOrganization
            );
          }

          this.expandedTeams.forEach((levelName) => {
            const level = this.findNodeByLevelName(
              levelName,
              this.neededOrganization
            );
            if (level && !ancestors.has(level.id)) {
              this.expandedTeams.delete(levelName);
            }
          });
          this.expandedTeams.add(this.node.level_name);
          this.shownLevelMembers.add(this.node.level_name);
          this.levelSelected(this.node);
        }
      }
      this.$nextTick(() => {
        const selectedLevelRef = this.$refs[`level-${this.node.level_name}`];
        if (selectedLevelRef) {
          selectedLevelRef.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      });
    },
    findNodeById(id, nodes) {
      for (let node of nodes) {
        if (node.id === id) return node;
        if (node.children) {
          const found = this.findNodeById(id, node.children);
          if (found) return found;
        }
      }
      return null;
    },
    findNodeByLevelName(levelName, nodes) {
      for (let node of nodes) {
        if (node.level_name === levelName) return node;
        if (node.children) {
          const found = this.findNodeByLevelName(levelName, node.children);
          if (found) return found;
        }
      }
      return null;
    },

    memberSelected(member, levelName, isInitialMember) {
      if (
        this.selectedMembers.has(
          `${member.email} - ${levelName} - ${member.job_description} - ${member.firstname} ${member.lastname}`
        )
      ) {
        this.selectedMembers.clear();
      } else {
      }
      this.$parent.memberSelected(member, levelName, isInitialMember);
    },
    memberDeselected(member, levelName) {
      this.$parent.memberDeselected(member, levelName);
    },

    levelSelected(level) {
      this.$parent.levelSelected(level);
    },
  },
};
</script>

<style scoped>
.level-icons {
  margin-bottom: 15px;
  cursor: pointer;
}
.level-icons:hover {
  color: #0071ce;
}
.bold-text {
  font-weight: bold;
  padding: 10px 0;
}

.regular-text {
  font-weight: normal;
  padding: 5px 0;
}

.selectedMember {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 3px 18px;
  cursor: pointer;
  background: #f7fafe;
  border-left: 5px solid #3399ff;
}
.selectedLevel {
  background: #f7fafe;
  border-left: 5px solid #3399ff;
}
.regularmember {
  display: flex;
  align-items: center;
  padding: 3px 18px;
  gap: 20px;
  cursor: pointer;
}
.regularmember:hover {
  background: #eff1f3;
}
.regularLevel {
  background: #d5d9df;
}
.admin-member {
  border: 2px solid gold;
}

.responsible-member {
  border: 2px solid rgb(148, 142, 142);
}
.avatar_ribbon {
  transform: scale(0.6);
  border-radius: 50%;
  overflow: hidden;
  border: 1px;
  height: 60px;
  width: 60px;
  cursor: pointer;
}
.regular-member {
  border: 1px solid lightgray;
}
</style>
