<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3>{{ $t("Delete Action") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>
      <div style="font-size: 15px">
        {{ $t("Are you sure you want to delete the action") }}
        <b>{{ selectedAction ? selectedAction.action_details.action : "" }}</b>
        {{ $t("for this user") }}
        ?
      </div>
      <template v-slot:footer>
        <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
        <CButton
          @click="deleteAction"
          color="primary"
          class="modal-confirm"
          data-testid="confirmActionDelete"
        >
          {{ $t("Delete") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { ActionsService } from "@/services/actions.js";
import { showMessage } from "../../../utils/helpers";
import DetailsModal from "../../reporting/components/DetailsModal.vue";
export default {
  name: "DeleteActionModal",
  data() {
    return {};
  },
  props: {
    show: Boolean,
    selectedAction: Object,
    selectedMembers: Set,
  },
  methods: {
    close() {
      this.$emit("close");
    },
    extractDetails(set) {
      const data = Array.from(set)[0];
      const parts = data.split(" - ");

      const [email, levelName, jobDescription, username] = parts;
      return { email, levelName, jobDescription };
    },

    async deleteAction() {
      const details = this.extractDetails(this.selectedMembers);
      const { data } = await ActionsService.delete_action({
        action_id: this.selectedAction.action_details.id,
        user_email: details.email,
        job_description: details.jobDescription,
        level_name: details.levelName,
        _id: this.selectedAction.action_details._id,
      });
      if (data.success) {
        showMessage("success", "Successfully deleted action");
        this.close();
        this.$parent.actionDeleted(this.selectedAction);
      } else {
        showMessage("error", "Failed to delete action");
      }
    },
  },
};
</script>

<style></style>
