<template>
  <div class="fullActionPage">
    <div>
      <div class="topStyles-mobile mt-4 mb-5">
        <div class="d-flex align-items-center gap-4">
          <h1
            :style="isMobile ? 'font-size:2em;' : 'font-size: 3em;'"
            class="mb-0"
          >
            {{ $t("Action Plan") }}
          </h1>
          <div class="progress_bar">
            <span>{{ formatAdvancement(averageAdvancement) || 0 }} %</span>
            <CProgress style="height: 5px; width: 200px">
              <CProgressBar :value="averageAdvancement"></CProgressBar>
            </CProgress>
          </div>
        </div>

        <div
          style="
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            width: 100%;
          "
        >
          <div class="d-flex">
            <div
              class="exportBtn mr-2"
              @click="getListActions"
              v-if="doesHaveRight('exportActions')"
            >
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M6.28571 19C3.91878 19 2 17.1038 2 14.7647C2 12.4256 3.91878 10.5294 6.28571 10.5294C6.56983 10.5294 6.8475 10.5567 7.11616 10.6089M14.381 8.02721C14.9767 7.81911 15.6178 7.70588 16.2857 7.70588C16.9404 7.70588 17.5693 7.81468 18.1551 8.01498M7.11616 10.6089C6.88706 9.9978 6.7619 9.33687 6.7619 8.64706C6.7619 5.52827 9.32028 3 12.4762 3C15.4159 3 17.8371 5.19371 18.1551 8.01498M7.11616 10.6089C7.68059 10.7184 8.20528 10.9374 8.66667 11.2426M18.1551 8.01498C20.393 8.78024 22 10.8811 22 13.3529C22 16.0599 20.0726 18.3221 17.5 18.8722"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  ></path>
                  <path
                    d="M12 22V16M12 22L14 20M12 22L10 20"
                    stroke="#000000"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </g>
              </svg>
            </div>
            <CButton
              v-if="
                tableItems.length &&
                !isLoadingData &&
                doesHaveRight('orderActions')
              "
              class="mr-2 changeOrderBtn"
              @click="showOrderModal = true"
            >
              {{ $t("Change order") }}
            </CButton>

            <CDropdown
              v-if="
                doesHaveRight('addAllAction') ||
                doesHaveRight('addTeamAction') ||
                doesHaveRight('addOwnAction')
              "
              :disabled="isLoadingData"
              :togglerText="$t('Add Action')"
              color="primary"
            >
              <CDropdownItem
                @click="openIndividualAction"
                style="cursor: pointer"
              >
                {{ $t("Individual Action") }}
              </CDropdownItem>
              <CDropdownItem
                @click="openMultipleActions"
                style="cursor: pointer"
              >
                {{ $t("Multiple Actions") }}
              </CDropdownItem>
              <CDropdownItem @click="getTemplate" style="cursor: pointer">
                {{ $t("Get Template") }}
              </CDropdownItem>
            </CDropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="action-content">
      <div>
        <TeamList
          :current-view="currentView"
          :allActions="allActions"
          :team-members="teamMembers"
          :neededOrganization="neededOrganization"
          :orgMembers="orgMembers"
          :me-details="meDetails"
          :isLoadingRibbonData="isLoadingRibbonData"
          :currentUser="currentUser"
          :expandedTeams="expandedTeams"
          :expandedProjectLevels="expandedProjectLevels"
          :shownLevelMembers="shownLevelMembers"
          :shownProjectLevelMembers="shownProjectLevelMembers"
          :selectedMembers="selectedMembers"
          :selectedLevels="selectedLevels"
          :initialMembers="initialMembers"
          :membersAvatars="membersAvatars"
          :noReset="noReset"
          @switch-view="switchView"
        />
      </div>

      <div
        style="
          width: 100%;
          position: relative;
          bottom: 15px;
          display: flex;
          flex-direction: column;
          gap: 10px;
        "
      >
        <div style="position: relative">
          <div class="topStyles">
            <div class="d-flex align-items-center gap-4">
              <h2 class="mb-0">{{ $t("Action Plan") }}</h2>
              <div class="progress_bar">
                <span>{{ formatAdvancement(averageAdvancement) || 0 }} %</span>
                <CProgress
                  style="height: 5px; width: 200px; background: lightgray"
                >
                  <CProgressBar :value="averageAdvancement"></CProgressBar>
                </CProgress>
              </div>
            </div>

            <div style="display: flex; justify-content: flex-end; gap: 16px">
              <div class="d-flex">
                <div
                  class="exportBtn mr-2"
                  @click="getListActions"
                  v-if="doesHaveRight('exportActions')"
                >
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M6.28571 19C3.91878 19 2 17.1038 2 14.7647C2 12.4256 3.91878 10.5294 6.28571 10.5294C6.56983 10.5294 6.8475 10.5567 7.11616 10.6089M14.381 8.02721C14.9767 7.81911 15.6178 7.70588 16.2857 7.70588C16.9404 7.70588 17.5693 7.81468 18.1551 8.01498M7.11616 10.6089C6.88706 9.9978 6.7619 9.33687 6.7619 8.64706C6.7619 5.52827 9.32028 3 12.4762 3C15.4159 3 17.8371 5.19371 18.1551 8.01498M7.11616 10.6089C7.68059 10.7184 8.20528 10.9374 8.66667 11.2426M18.1551 8.01498C20.393 8.78024 22 10.8811 22 13.3529C22 16.0599 20.0726 18.3221 17.5 18.8722"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      ></path>
                      <path
                        d="M12 22V16M12 22L14 20M12 22L10 20"
                        stroke="#000000"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                  </svg>
                </div>

                <CButton
                  v-if="
                    tableItems.length &&
                    !isLoadingData &&
                    doesHaveRight('orderActions')
                  "
                  class="mr-2 changeOrderBtn"
                  @click="showOrderModal = true"
                >
                  {{ $t("Change order") }}
                </CButton>
                <CDropdown
                  v-if="
                    doesHaveRight('addAllAction') ||
                    doesHaveRight('addTeamAction') ||
                    doesHaveRight('addOwnAction')
                  "
                  :disabled="isLoadingData"
                  :togglerText="$t('Add Action')"
                  color="primary"
                >
                  <CDropdownItem
                    @click="openIndividualAction"
                    style="cursor: pointer"
                  >
                    {{ $t("Individual Action") }}
                  </CDropdownItem>
                  <CDropdownItem
                    @click="openMultipleActions"
                    style="cursor: pointer"
                  >
                    {{ $t("Multiple Actions") }}
                  </CDropdownItem>
                  <CDropdownItem @click="getTemplate" style="cursor: pointer">
                    {{ $t("Get Template") }}
                  </CDropdownItem>
                </CDropdown>
              </div>
            </div>
          </div>

          <div
            class="table-content"
            v-if="multipleMembersSelected && isDataReady"
          >
            <div class="tableContainer">
              <ActionTable
                :table-items="tableItems"
                :is-details-open="isDetailsOpen"
                :selected-row-index="selectedRowIndex"
                :selectedPhaseIndex="selectedPhaseIndex"
                :selectedCategoryIndex="selectedCategoryIndex"
                :current-page="currentPage"
                :displayed-items="displayedItems"
                :per-page="perPage"
                :per-options="perOptions"
                :sortTypes="sortTypes"
                :isLoadingData="isLoadingData"
                :selectedMembers="selectedMembers"
                :selectedLevels="selectedLevels"
                :phaseOptions="phaseOptions"
                :categoryOptions="categoryOptions"
                :usersOptions="usersOptions"
                :usersModal="usersModal"
                :orgMembers="orgMembers"
                :listOfMembers="listOfMembers"
                :allOrgLevelsOptions="allOrgLevelsOptions"
                @toggle-chevron="toggleChevron"
                @update-advancement="updateAdvancement"
                @previous-page="previousPage"
                @next-page="nextPage"
                @change-per-page="changePerPage"
                @phaseDirection="phaseDirection"
                @categoryDirection="categoryDirection"
                @toggleSort="toggleSort"
                @swapCategories="swapCategories"
              />
            </div>
          </div>
          <div v-else class="table-content" style="height: calc(100vh - 195px)">
            <div
              v-if="tableItems.length && !isLoadingData && isDataReady"
              v-for="(phase, phaseIndex) in tableItems"
              :key="phaseIndex"
              class="plan"
              style="border: 1px solid rgb(195, 195, 195)"
            >
              <div>
                <div
                  style="
                    background: #e9e9e9 !important;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    gap: 20px;
                  "
                >
                  <div>
                    <i
                      :class="{
                        'fa fa-chevron-up mr-2 ': isPhaseExpanded(phaseIndex),
                        'fa fa-chevron-down  mr-2 ':
                          !isPhaseExpanded(phaseIndex),
                      }"
                      @click="togglePhase(phaseIndex)"
                      class="pointer"
                      style="font-size: 14px"
                    ></i>
                    <span style="font-weight: bold">{{ phaseIndex + 1 }}</span>
                  </div>
                  <h4 class="ml-2 mt-1" style="font-weight: bold">
                    {{ phase.phase_name }}
                  </h4>
                </div>
                <div
                  v-if="isPhaseExpanded(phaseIndex)"
                  v-for="(category, categoryIndex) in phase.categories"
                  :key="categoryIndex"
                  class=""
                >
                  <div class="overflow-x-auto">
                    <div
                      style="
                        display: flex;
                        align-items: center;
                        padding: 10px 20px;
                        gap: 20px;
                        background: white;
                      "
                    >
                      <div style="gap: 5px; display: flex; align-items: center">
                        <i
                          :class="{
                            'fa fa-chevron-up': isCategoryExpanded(
                              phaseIndex,
                              categoryIndex
                            ),
                            'fa fa-chevron-down': !isCategoryExpanded(
                              phaseIndex,
                              categoryIndex
                            ),
                          }"
                          @click="toggleCategory(phaseIndex, categoryIndex)"
                          class="pointer mt-1"
                        ></i>
                        <span style="font-weight: bold">{{
                          phaseIndex + 1 + "." + (categoryIndex + 1)
                        }}</span>
                      </div>
                      <div style="display: flex; align-items: center">
                        <h4 style="font-size: 16px; margin: 0; padding: 0">
                          {{ category.category_name }}
                        </h4>
                      </div>
                    </div>
                    <div class="overflow-auto">
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="width: 10px"></th>
                            <th style="width: 20px">
                              <span
                                @click="
                                  toggleSort(
                                    'id',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['id']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 60px; white-space: wrap">
                              {{ $t("Action") }}
                              <span
                                @click="
                                  toggleSort(
                                    'action',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['action']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 120px">
                              {{ $t("Description") }}
                              <span
                                @click="
                                  toggleSort(
                                    'subject',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['subject']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 50px; white-space: wrap">
                              {{ $t("Deadline")
                              }}<span
                                @click="
                                  toggleSort(
                                    'deadline',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['deadline']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 70px">
                              {{ $t("Progress") }}
                              <span
                                @click="
                                  toggleSort(
                                    'advancement',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['advancement']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 50px">
                              {{ $t("Points") }}
                              <span
                                @click="
                                  toggleSort(
                                    'award',
                                    phaseIndex,
                                    categoryIndex,
                                    sortTypes['award']
                                  )
                                "
                                style="margin-left: 5px; cursor: pointer"
                                ><i class="fa fa-sort" style="color: black"></i
                              ></span>
                            </th>
                            <th style="width: 50px; white-space: wrap">
                              {{ $t("Actions") }}
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          v-if="isCategoryExpanded(phaseIndex, categoryIndex)"
                        >
                          <template
                            v-for="(item, index) in category.actions"
                            :key="index"
                          >
                            <tr>
                              <td></td>
                              <td>
                                <!-- {{ item.action_details.id }} -->
                                {{ phaseIndex + 1 }}.{{ categoryIndex + 1 }}.{{
                                  index + 1
                                }}
                              </td>
                              <td
                                style="
                                  word-wrap: break-word;
                                  white-space: normal;
                                  max-width: 60px;
                                  overflow: hidden;
                                "
                              >
                                {{ item.action_details.action }}
                              </td>
                              <td
                                style="
                                  word-wrap: break-word;
                                  white-space: normal;
                                  max-width: 120px;
                                  overflow: hidden;
                                "
                              >
                                {{ item.action_details.subject }}
                              </td>
                              <td>
                                {{
                                  getFormattedDate(item.action_details.deadline)
                                }}
                              </td>
                              <td class="d-flex" style="white-space: nowrap">
                                <input
                                  type="number"
                                  min="0"
                                  max="100"
                                  style="
                                    width: 50px;
                                    height: 20px;
                                    margin-right: 5px;
                                  "
                                  :value="getProgressBarValue(item)"
                                  @change="
                                    updateAdvancement(
                                      item,
                                      $event.target.value,
                                      phase.phase_name,
                                      category.category_name,
                                      item.action_details.action
                                    )
                                  "
                                  :disabled="!userCanEditProgress()"
                                />
                                %

                                <CProgress
                                  class="mt-2 ml-2"
                                  style="height: 5px; width: 100px"
                                >
                                  <CProgressBar
                                    :style="{
                                      backgroundColor:
                                        getProgressBarColor(item),
                                    }"
                                    :value="getProgressBarValue(item)"
                                  ></CProgressBar>
                                </CProgress>
                              </td>
                              <td>
                                <span
                                  style="color: #3cbc3c"
                                  v-if="getUserAdvancement(item) === 100"
                                  v-c-tooltip="{
                                    content: $t('Success'),
                                    placement: 'top',
                                  }"
                                >
                                  <i
                                    class="fa fa-check"
                                    style="color: #3cbc3c"
                                  ></i>
                                  {{ getSelectedMemberAward(item) }} pts
                                </span>
                                <span v-else>
                                  + {{ getSelectedMemberAward(item) }} pts
                                </span>
                              </td>

                              <td>
                                <div style="margin-left: 5px">
                                  <span
                                    v-if="
                                      userCanEditAction(
                                        getSelectedMemberValue(item)
                                      )
                                    "
                                    v-c-tooltip="{
                                      content: $t('Edit Action'),
                                      placement: 'top',
                                    }"
                                    data-testid="editActionIcon"
                                    @click="
                                      openEditModal(phase, category, item)
                                    "
                                    style="
                                      color: #3399ff;
                                      cursor: pointer;
                                      font-size: 12px;
                                    "
                                    >{{ $t("Edit") }}</span
                                  >

                                  <span
                                    style="
                                      color: #e45353;
                                      cursor: pointer;
                                      margin-left: 10px;
                                      font-size: 12px;
                                    "
                                    v-if="
                                      userCanDeleteAction(
                                        getSelectedMemberValue(item)
                                      )
                                    "
                                    v-c-tooltip="{
                                      content: $t('Delete Action'),
                                      placement: 'top',
                                    }"
                                    @click="openDeleteModal(item)"
                                    >{{ $t("Delete") }}</span
                                  >
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else-if="!tableItems.length"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
                height: 70vh;
              "
            >
              <h1 v-if="!isLoadingData">
                {{ $t("No actions available") }}
              </h1>

              <ContentLoader
                v-else
                :speed="2"
                primaryColor="rgb(230, 230, 230)"
                secondaryColor="rgb(240, 240, 240)"
                class="spinner_wrapper mt-4"
              ></ContentLoader>
            </div>
            <div v-else-if="!isDataReady">
              <ContentLoader
                :speed="2"
                primaryColor="rgb(230, 230, 230)"
                secondaryColor="rgb(240, 240, 240)"
                class="spinner_wrapper mt-4"
              ></ContentLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :style="{ display: showIndividualAction ? block : none }">
      <ActionsModals
        :key="testKey"
        :showIndividualAction="showIndividualAction"
        :showMultipleActions="showMultipleActions"
        :categoryOptions="categoryOptions"
        :phaseOptions="phaseOptions"
        :orgMembers="orgMembers"
        :usersModal="usersModal"
        :selectedLevels="selectedLevels"
        :selectedMembers="selectedMembers"
        :preSelectedUsers="preSelectedUsers"
        :otherUsers="otherUsers"
        :preSelectedLevels="preSelectedLevels"
        :otherLevels="otherLevels"
        :selectedRoles="selectedRoles"
        :scopeSelectedUsersOptions="scopeSelectedUsersOptions"
        :scopeOtherUsersOptions="scopeOtherUsersOptions"
        :scopeSelectedLevelOptions="scopeSelectedLevelOptions"
        :scopeOtherLevelsOptions="scopeOtherLevelsOptions"
        :userCanAddActionForAll="userCanAddActionForAll"
        :userCanAddTeamActionOnly="userCanAddTeamActionOnly"
        :userCanAddOwnActionOnly="userCanAddOwnActionOnly"
        @close-individual-action="closeIndividualAction"
        @add-action="addAction"
        @close-multiple-actions="closeMultipleActions"
        @upload-files="handleUpload"
        @update-phase="updatePhase"
        @update-category="updateCategory"
        @update-role="updateRole"
        @update-users="updateUsers"
        @update-action-title="updateActionTitle"
        @update-subject-text="updateSubject"
        @update-due-date="updateDueDate"
        @update-progress="updateProgress"
      />
    </div>
    <EditActionModal
      :show="showEditActionModal"
      :phaseOptions="phaseOptions"
      :categoryOptions="categoryOptions"
      :usersOptions="usersOptions"
      :usersModal="usersModal"
      :orgMembers="orgMembers"
      :selectedAction="selectedAction"
      :selectedPhase="selectedPhase"
      :selectedCategory="selectedCategory"
      :listOfMembers="listOfMembers"
      :selectedMembers="selectedMembers"
      :userCanAddActionForAll="userCanAddActionForAll"
      :userCanAddTeamActionOnly="userCanAddTeamActionOnly"
      :userCanAddOwnActionOnly="userCanAddOwnActionOnly"
      @close="showEditActionModal = false"
    />
    <DeleteActionModal
      :show="showDeleteActionModal"
      :selectedAction="selectedAction"
      :selectedMembers="selectedMembers"
      @close="showDeleteActionModal = false"
    />
    <OrderModal
      :show="showOrderModal"
      @close="showOrderModal = false"
      :tableItems="tableItems"
      :allActions="allActions"
    />
  </div>
</template>

<script>
import TeamList from "./components/TeamList.vue";
import ActionTable from "./components/ActionTable.vue";
import ActionsModals from "./components/ActionsModals.vue";
import EditActionModal from "./components/EditActionModal.vue";
import DeleteActionModal from "./components/DeleteActionModal.vue";
import OrderModal from "./components/OrderModal.vue";
import { ActionsService } from "../../services/actions";
import { ProfileService } from "../../services/profile";
import { OrganizationsService } from "@/services/organizations";
import { FilesService } from "@/services/files";
import { ProjectsService } from "@/services/projects";
import { UsersService } from "@/services/users";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import { mapState } from "vuex";
import { ContentLoader } from "vue-content-loader";
import Message from "vue-m-message";
import { showMessage, updateMyData } from "../../utils/helpers";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import { useGlobalStore } from "@/stores/store";
import { useUserStore } from "@/stores/UserStore";

import { mapWritableState } from "pinia";
import { ElLoading } from "element-plus";

export default {
  name: "Action Plan",
  components: {
    TeamList,
    ActionTable,
    ActionsModals,
    Treeselect,
    EditActionModal,
    DeleteActionModal,
    OrderModal,
    OrganizationSelector,
    ContentLoader,
    OrganizationSelector,
  },
  data: () => {
    return {
      userStore: useUserStore(),
      globalStore: useGlobalStore(),
      membersAvatars: [],
      something: false,
      itemsOrder: [],
      selectedMembers: new Set(),
      selectedLevels: new Set(),
      multipleMembersSelected: false,
      allActions: [],
      testKey: 0,
      orgsWithProjects: [],
      orgMembers: [],
      expandedTeams: new Set(),
      expandedProjectLevels: new Set(),
      shownLevelMembers: new Set(),
      shownProjectLevelMembers: new Set(),
      neededOrganization: [],
      tableItems: [],
      ribbonData: [],
      dropdownvisible: false,
      isOpen: false,
      selectedOption: null,
      projectRole: [{ id: "All", label: "All" }],
      orgOptions: [],
      projectOptions: [],
      isDetailsOpen: false,
      selectedRowIndex: null,
      selectedPhaseIndex: null,
      selectedCategoryIndex: null,
      showIndividualAction: false,
      showMultipleActions: false,
      categoryOptions: [],
      isExpanded: false,
      teamMembers: [],
      meDetails: [],
      currentView: "Individual",
      usersOptions: [],
      initialUsers: [],
      fields: [
        { key: "id", label: "#" },
        { key: "subject", label: "Subject" },
        { key: "action", label: "Action" },
        { key: "deadline", label: "Deadline" },
        { key: "advancement", label: "Advancement" },
        { key: "awards", label: "Awards" },
      ],
      items: [],
      usersArray: [],
      phaseOptions: [],
      points: 100,
      expandedCategories: new Set(),
      expandedPhases: new Set(),
      expandedRows: new Set(),
      selectedLanguage: "",
      changedLanguage: false,
      selectedLanguageValue: "",
      languages: ["EN", "FR"],
      countries: [
        { value: "en", label: "English", code: "GB-UKM" },
        { value: "fr", label: "Français", code: "FR" },
        { value: "deu", label: "Deutsch", code: "DEU" },
        { value: "es", label: "Español", code: "ES" },
      ],
      currentUser: null,
      downPhases: new Set(),
      downCategories: new Set(),
      sortTypes: {
        id: "asc",
        deadline: "asc",
        advancement: "asc",
        award: "asc",
        subject: "asc",
      },
      showEditActionModal: false,
      showDeleteActionModal: false,
      showOrderModal: false,
      locations: [],
      listOfMembers: [],
      isLoadingData: true,
      isLoadingRibbonData: true,
      expandedCountries: new Set(),
      expandedDirections: new Set(),
      expandedJobs: new Set(),
      selectedAction: null,
      selectedPhase: null,
      selectedCategory: null,
      avgAdvancement: 0,
      currentOrder: [],
      isDataReady: false,
      initialMembers: new Set(),
      isInitialSelectionDone: false,
      usersModal: [],
      noReset: false,
      preSelectedUsers: null,
      otherUsers: null,
      preSelectedLevels: null,
      otherLevels: null,
      selectedRoles: null,
      scopeSelectedUsersOptions: [],
      scopeOtherUsersOptions: [],
      scopeSelectedLevelOptions: [],
      scopeOtherLevelsOptions: [],
      allOrgLevelsOptions: [],
      actionsPermissions: [],
      userCanAddActionForAll: false,
      userCanAddTeamActionOnly: false,
      userCanAddOwnActionOnly: false,
    };
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
      selectedJob: (state) => state.selectedJob,
      // permissions: (state) => state.permissions,
    }),

    ...mapWritableState(useGlobalStore, {
      isMobile: "isMobile",
    }),

    averageAdvancement(origin) {
      if (this.selectedMembers.size === 0 && this.selectedLevels.size === 0) {
        return 0;
      }
      if (this.selectedMembers.size > 0) {
        const selectedMemberValue = this.selectedMembers.values().next().value;
        const parts = selectedMemberValue.split(" - ");
        if (parts.length < 4) {
          console.error(
            "Selected member value does not contain expected information:",
            selectedMemberValue
          );
          return 0;
        }
        const [email, firstLevel, jobDescription, username] = parts;

        let sumWeightedProgress = 0;
        let sumAwards = 0;

        this.tableItems.forEach((phase) => {
          phase.categories.forEach((category) => {
            category.actions.forEach((action) => {
              const user = action.users.find(
                (user) => user.email === email && user.level_name == firstLevel
              );

              if (user) {
                const weightedProgress = (user.advancement / 100) * user.award;
                sumWeightedProgress += weightedProgress;
                sumAwards += user.award;
              }
            });
          });
        });

        const overallWeightedProgress =
          sumAwards > 0 ? sumWeightedProgress / sumAwards : 0;
        return overallWeightedProgress * 100;
      }
      if (this.selectedLevels.size > 0) {
        function collectMembers(level) {
          let members = [];

          // Add the current level's members if any
          if (level.members && level.members.length > 0) {
            members.push(...level.members);
          }

          // Recursively collect members from children
          if (level.children && level.children.length > 0) {
            level.children.forEach((child) => {
              members = members.concat(collectMembers(child));
            });
          }

          return members;
        }
        function collectLevelNames(level) {
          let levelNames = [level.level_name];

          if (level.children && level.children.length > 0) {
            level.children.forEach((child) => {
              levelNames = levelNames.concat(collectLevelNames(child));
            });
          }

          return levelNames;
        }
        const selectedLevelsArray = Array.from(this.selectedLevels);
        let allLevelNames = [];
        selectedLevelsArray.forEach((level) => {
          allLevelNames = allLevelNames.concat(collectLevelNames(level));
        });

        let sumWeightedProgress = 0;
        let totalAwards = 0;

        this.tableItems.forEach((phase) => {
          phase.categories.forEach((category) => {
            category.actions.forEach((action) => {
              action.users.forEach((user) => {
                if (allLevelNames.includes(user.level_name)) {
                  const weightedProgress =
                    (user.advancement / 100) * user.award;
                  sumWeightedProgress += weightedProgress;
                  totalAwards += user.award;
                }
              });
            });
          });
        });

        const overallWeightedProgress =
          totalAwards > 0 ? (sumWeightedProgress / totalAwards) * 100 : 0;
        return overallWeightedProgress;
      }

      return 0;
    },

    selectedMemberEmail() {
      if (this.selectedMembers.size === 1) {
        const memberString = [...this.selectedMembers][0];
        const emailMatch = memberString.match(/[\w.-]+@[\w.-]+/);
        return emailMatch ? emailMatch[0] : null;
      }
      return null;
    },
  },
  async created() {
    this.refreshEverything();
  },
  watch: {
    tableItems: {
      immediate: true,
      handler(newVal) {
        this.expandedCategories.clear();
        this.expandedPhases.clear();
        newVal.forEach((phase, phaseIndex) => {
          this.expandedPhases.add(phaseIndex);
          phase.categories.forEach((_, categoryIndex) => {
            this.expandedCategories.add(`${phaseIndex}-${categoryIndex}`);
          });
        });
      },
    },
    selectedLevels: {
      handler() {
        this.updateModalOptions();
      },
      deep: true,
    },
    selectedMembers: {
      handler() {
        this.updateModalOptions();
      },
      deep: true,
    },
  },

  methods: {
    userCanDeleteAction(actionUser) {
      if (this.doesHaveRight("removeAllAction")) {
        return true;
      } else if (this.doesHaveRight("removeTeamAction")) {
        const neededLevel = this.usersFullLevel();

        if (this.selectedMembers.size > 0) {
          const selectedMemberString = Array.from(this.selectedMembers)[0];
          const [email, levelname, jobDescription] = selectedMemberString
            .split(" - ")
            .map((part) => part.trim());

          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        } else if (this.selectedLevels.size > 0) {
          const levelname = actionUser.level_name;
          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        }
      } else if (this.doesHaveRight("removeOwnAction")) {
        if (actionUser) {
          return actionUser.email === this.userData.email;
        } else {
          return true;
        }
      }
      return false;
    },
    userCanEditAction(actionUser) {
      if (this.doesHaveRight("modifyAllAction")) {
        return true;
      } else if (this.doesHaveRight("modifyTeamAction")) {
        const neededLevel = this.usersFullLevel();

        if (this.selectedMembers.size > 0) {
          const selectedMemberString = Array.from(this.selectedMembers)[0];
          const [email, levelname, jobDescription] = selectedMemberString
            .split(" - ")
            .map((part) => part.trim());

          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        } else if (this.selectedLevels.size > 0) {
          const levelname = actionUser.level_name;
          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        }
      } else if (this.doesHaveRight("modifyOwnAction")) {
        if (actionUser) {
          return actionUser.email === this.userData.email;
        } else {
          return true;
        }
      }
      return false;
    },
    userCanEditProgress(actionUser) {
      if (this.doesHaveRight("modifyAllProgress")) {
        return true;
      } else if (this.doesHaveRight("modifyTeamProgress")) {
        const neededLevel = this.usersFullLevel();

        if (this.selectedMembers.size > 0) {
          const selectedMemberString = Array.from(this.selectedMembers)[0];
          const [email, levelname, jobDescription] = selectedMemberString
            .split(" - ")
            .map((part) => part.trim());

          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        } else if (this.selectedLevels.size > 0) {
          const levelname = actionUser.level_name;
          const isUserInSameTeam = (level) => {
            if (level.level_name === levelname) {
              return true;
            }
            if (level.children && level.children.length > 0) {
              return level.children.some((child) => isUserInSameTeam(child));
            }
            return false;
          };
          return isUserInSameTeam(neededLevel);
        }
      } else if (this.doesHaveRight("modifyOwnProgress")) {
        if (actionUser) {
          return actionUser.email === this.userData.email;
        } else {
          return true;
        }
      }
      return false;
    },
    doesHaveRight(rightName) {
      if (this.actionsPermissions && this.actionsPermissions.length > 0) {
        return this.actionsPermissions.find((right) => right.name === rightName)
          .status;
      }
    },
    updateModalOptions() {
      // this.userCanAddActionForAll = false;
      // this.userCanAddTeamActionOnly = false;
      // this.userCanAddOwnActionOnly = true;
      this.scopeSelectedLevelOptions = [];
      this.scopeOtherLevelsOptions = [];
      this.scopeSelectedUsersOptions = [];
      this.scopeOtherUsersOptions = [];
      let selectedEmail, selectedLevel, selectedJobDescription;
      this.preSelectedUsers = [];
      this.otherUsers = [];
      this.scopeSelectedUsersOptions = [];

      if (this.selectedMembers.size > 0) {
        const selectedMemberString = Array.from(this.selectedMembers)[0];
        [selectedEmail, selectedLevel, selectedJobDescription] =
          selectedMemberString.split(" - ").map((part) => part.trim());
        if (selectedEmail) {
          const selectedValueString = `${selectedEmail} (${selectedLevel} - ${selectedJobDescription})`;
          this.scopeSelectedUsersOptions.push({
            value: selectedValueString,
            label: selectedValueString,
          });
          this.preSelectedUsers.push(selectedValueString);
        }
      }
      this.orgMembers.forEach((member) => {
        member.roles.forEach((role) => {
          const email = member.email;
          const valueString = `${email} (${role.level_name} - ${role.job_description})`;

          if (
            !(
              email === selectedEmail &&
              role.level_name === selectedLevel &&
              role.job_description === selectedJobDescription
            )
          ) {
            this.scopeOtherUsersOptions.push({
              value: valueString,
              label: valueString,
            });
          }
        });
      });

      this.scopeOtherUsersOptions = [...new Set(this.scopeOtherUsersOptions)];

      // FOR LEVELS
      this.preSelectedLevels = [];
      this.scopeSelectedLevelOptions = [];
      this.scopeOtherLevelsOptions = [];

      const extractLevelNames = (levelObject, includeChildren) => {
        const levelNames = [levelObject.level_name];
        if (includeChildren && levelObject.children) {
          levelObject.children.forEach((child) => {
            levelNames.push(...extractLevelNames(child, includeChildren));
          });
        }
        return levelNames;
      };

      if (this.selectedLevels.size > 0) {
        const selectedLevelObject = Array.from(this.selectedLevels)[0];
        const selectedLevelNames = extractLevelNames(selectedLevelObject, true);
        this.preSelectedLevels.push(...selectedLevelNames);
        this.scopeSelectedLevelOptions = selectedLevelNames.map((name) => ({
          value: name,
          label: name,
        }));
      }

      const addOtherLevels = (levelObject) => {
        if (!this.preSelectedLevels.includes(levelObject.level_name)) {
          this.scopeOtherLevelsOptions.push({
            value: levelObject.level_name,
            label: levelObject.level_name,
          });
        }
        if (levelObject.children) {
          levelObject.children.forEach((child) => addOtherLevels(child));
        }
      };

      this.neededOrganization.forEach((levelObject) => {
        addOtherLevels(levelObject);
      });

      this.scopeOtherLevelsOptions = this.scopeOtherLevelsOptions.filter(
        (option, index, self) =>
          index ===
          self.findIndex(
            (t) => t.value === option.value && t.label === option.label
          )
      );

      // this.userCanAddTeamActionOnly = true;
      if (this.userCanAddTeamActionOnly) {
        if (this.scopeSelectedUsersOptions.length > 0) {
          this.scopeSelectedUsersOptions =
            this.scopeSelectedUsersOptions.filter((option) => {
              const optionLevelName = option.value
                .match(/\(([^)]+)\)/)[1]
                .split(" - ")[0];

              const isLevelOrChildLevel = (level) => {
                if (level.level_name === optionLevelName) {
                  return true;
                }
                if (level.children && level.children.length > 0) {
                  return level.children.some((child) =>
                    isLevelOrChildLevel(child)
                  );
                }
                return false;
              };

              return isLevelOrChildLevel(this.usersFullLevel());
            });
        }
        if (this.scopeOtherUsersOptions.length > 0) {
          this.scopeOtherUsersOptions = this.scopeOtherUsersOptions.filter(
            (option) => {
              const optionLevelName = option.value
                .match(/\(([^)]+)\)/)[1]
                .split(" - ")[0];

              const isLevelOrChildLevel = (level) => {
                if (level.level_name === optionLevelName) {
                  return true;
                }
                if (level.children && level.children.length > 0) {
                  return level.children.some((child) =>
                    isLevelOrChildLevel(child)
                  );
                }
                return false;
              };

              return isLevelOrChildLevel(this.usersFullLevel());
            }
          );
        }
        if (this.scopeSelectedLevelOptions.length > 0) {
          this.scopeSelectedLevelOptions =
            this.scopeSelectedLevelOptions.filter((option) => {
              const isLevelOrChildLevel = (level) => {
                if (level.level_name === option.value) {
                  return true;
                }
                if (level.children && level.children.length > 0) {
                  return level.children.some((child) =>
                    isLevelOrChildLevel(child)
                  );
                }
                return false;
              };

              return isLevelOrChildLevel(this.usersFullLevel());
            });
        }
        if (this.scopeOtherLevelsOptions.length > 0) {
          this.scopeOtherLevelsOptions = this.scopeOtherLevelsOptions.filter(
            (option) => {
              const isLevelOrChildLevel = (level) => {
                if (level.level_name === option.value) {
                  return true;
                }
                if (level.children && level.children.length > 0) {
                  return level.children.some((child) =>
                    isLevelOrChildLevel(child)
                  );
                }
                return false;
              };

              return isLevelOrChildLevel(this.usersFullLevel());
            }
          );
        }
        if (this.preSelectedLevels.length > 0) {
          this.preSelectedLevels = this.preSelectedLevels.filter((level) => {
            const isLevelOrChildLevel = (levelObject) => {
              if (levelObject.level_name === level) {
                return true;
              }
              if (levelObject.children && levelObject.children.length > 0) {
                return levelObject.children.some((child) =>
                  isLevelOrChildLevel(child)
                );
              }
              return false;
            };
            return isLevelOrChildLevel(this.usersFullLevel());
          });
        }
      }

      if (this.userCanAddOwnActionOnly) {
        let neededUserData = null;
        this.orgMembers.forEach((member) => {
          member.roles.forEach((role) => {
            if (
              role.level_name ===
                this.userData.current_job_description.level_name &&
              role.job_description ===
                Object.keys(this.userData.current_job_description)[0]
            ) {
              neededUserData = `${member.email} (${role.level_name} - ${role.job_description})`;
            }
          });
        });
        const neededOptions = [
          {
            value: neededUserData,
            label: neededUserData,
          },
        ];
        this.preSelectedUsers = [neededUserData];
        this.scopeSelectedUsersOptions = neededOptions;
      }
    },
    usersFullLevel() {
      const neededLevel = this.userData.current_job_description.level_name;
      const findLevel = (level) => {
        if (level.level_name === neededLevel) {
          return level;
        }
        if (level.children && level.children.length > 0) {
          for (let i = 0; i < level.children.length; i++) {
            const found = findLevel(level.children[i]);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };
      for (let i = 0; i < this.neededOrganization.length; i++) {
        const found = findLevel(this.neededOrganization[i]);
        if (found) {
          return found;
        }
      }
      return null;
    },
    async getListActions() {
      const { data, res } = await ActionsService.list_actions_export({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
        role: null,
        user: [],
        view: "Grouped",
      });
      if (res.status === 200) {
        showMessage("success", "List actions  downloaded successfully !");
      } else {
        showMessage("error", "Failed to download list actions !");
      }
    },
    getSelectedMemberAward(item) {
      const selectedMemberInfo = Array.from(this.selectedMembers)[0];

      const selectedMemberEmail = selectedMemberInfo.split(" - ")[0];

      const selectedUser = item.users.find(
        (user) => user.email === selectedMemberEmail
      );

      return selectedUser ? selectedUser.award : null;
    },
    getSelectedMemberValue(item) {
      const selectedMemberInfo = Array.from(this.selectedMembers)[0];

      const selectedMemberEmail = selectedMemberInfo.split(" - ")[0];

      const selectedUser = item.users.find(
        (user) => user.email === selectedMemberEmail
      );

      return selectedUser ? selectedUser : null;
    },

    async getMembersAvatars() {
      const { data, res } = await ProfileService.get_all_avatars();
      this.membersAvatars = data.data;
    },
    isDisabled(permission, item) {
      if (item.can_edit_action) {
        return false;
      }
      if (this.selectedMemberEmail) {
        const user = item.users.find(
          (user) => user.email === this.selectedMemberEmail
        );
        return user ? !user[permission] : true;
      }
      return true;
    },
    getUserAdvancement(item) {
      const memberInfo = [...this.selectedMembers][0];
      const email = memberInfo.split(" - ")[0];

      const user = item.users.find((user) => user.email === email);

      return user ? user.advancement : 0;
    },

    findMatchingUser(item) {
      return item.users.find((user) => {
        const userString = `${user.email} - ${user.level_name} - ${user.job_description} - ${user.firstname} ${user.lastname}`;
        return this.selectedMembers.has(userString);
      });
    },
    stopSelection() {
      this.isInitialSelectionDone = true;
    },
    getProgressBarColor(item) {
      const matchingUser = this.findMatchingUser(item);
      if (matchingUser && matchingUser.advancement === 100) {
        return "#3CBC3C";
      }
      return "";
    },

    getProgressBarValue(item) {
      const matchingUser = this.findMatchingUser(item);
      return matchingUser ? parseFloat(matchingUser.advancement) : 0;
    },
    collectEmailsFromLevel(level) {
      let emails = new Set();

      if (level && Array.isArray(level.members)) {
        level.members.forEach((member) => emails.add(member.email));
      }

      if (level && Array.isArray(level.children)) {
        level.children.forEach((child) => {
          let childEmails = this.collectEmailsFromLevel(child);
          childEmails.forEach((email) => emails.add(email));
        });
      }

      return emails;
    },
    computeAverageAdvancement(emails) {
      let totalAdvancement = 0;
      let count = 0;
      this.orgMembers.forEach((member) => {
        if (emails.has(member.email)) {
          totalAdvancement += member.advancement;
          count++;
        }
      });

      return count > 0 ? totalAdvancement / count : 0;
    },
    collectAdvancements(level) {
      let advancements = [];

      if (level.members) {
        level.members.forEach((member) => {
          const orgMember = this.orgMembers.find(
            (m) => m.email === member.email
          );
          if (orgMember) {
            advancements.push(orgMember.advancement);
          }
        });
      }

      if (level.children) {
        level.children.forEach((childLevel) => {
          advancements = advancements.concat(
            this.collectAdvancements(childLevel)
          );
        });
      }

      return advancements;
    },

    orderChanged(newOrder) {
      this.itemsOrder = newOrder;
      this.tableItems = this.reorderTableItems(this.tableItems, newOrder);
      this.allActions = this.reorderTableItems(this.allActions, newOrder);
    },
    actionDeleted(action) {
      this.deleteActionById(action, this.allActions, this.tableItems);
    },
    async deleteActionById(actionToDelete, allActions, tableItems) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      updateMyData();
      this.noReset = true;
      await this.getAllActionsList();
      await this.getOrgData();
      if (this.selectedMembers.size > 0) {
        await this.listActionsForSelectedMembers();
      }
      if (this.selectedLevels.size > 0) {
        await this.listActionsForLevels();
      }
      this.something = true;
      loading.close();
    },
    async editedAction(payload, actionDetails) {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      updateMyData();
      this.noReset = true;
      await this.getAllActionsList();
      await this.getOrgData();
      this.something = true;
      if (this.selectedMembers.size > 0) {
        await this.listActionsForSelectedMembers();
      }
      if (this.selectedLevels.size > 0) {
        await this.listActionsForLevels();
      }
      loading.close();
    },
    async updateActionsAndItems(
      payload,
      actionDetails,
      allActions,
      tableItems
    ) {
      const matchingUser = await this.findMatchingUser(actionDetails);

      this.tableItems.forEach((phase) => {
        phase.categories.forEach((category) => {
          category.actions.forEach((action) => {
            if (action.action_details.id === actionDetails.action_details.id) {
              action.action_details.action = payload.action;
              action.action_details.award = payload.award;
              action.action_details.subject = payload.subject;
              action.action_details.deadline = payload.deadline;

              payload.users.forEach((payloadUser) => {
                const userExists = action.users.some((actionUser) => {
                  return (
                    actionUser.email === payloadUser.email &&
                    actionUser.job_description ===
                      payloadUser.job_description &&
                    actionUser.level_name === payloadUser.level_name
                  );
                });

                if (!userExists) {
                  const orgMember = this.orgMembers.find(
                    (member) => member.email === payloadUser.email
                  );
                  const newUser = {
                    ...payloadUser,
                    firstname: orgMember ? orgMember.first_name : "",
                    lastname: orgMember ? orgMember.last_name : "",
                    can_delete_action: true,
                    can_edit_percentage: true,
                    can_edit_plan: true,
                  };
                  action.users.push(newUser);
                }
              });

              action.users = action.users.filter((actionUser) =>
                payload.users.some(
                  (payloadUser) =>
                    payloadUser.email === actionUser.email &&
                    payloadUser.job_description ===
                      actionUser.job_description &&
                    payloadUser.level_name === actionUser.level_name
                )
              );
            }
          });
        });
      });
      this.allActions.forEach((phase) => {
        phase.categories.forEach((category) => {
          category.actions.forEach((action) => {
            if (action.action_details.id === actionDetails.action_details.id) {
              action.action_details.action = payload.action;
              action.action_details.award = payload.award;
              action.action_details.subject = payload.subject;
              action.action_details.deadline = payload.deadline;

              payload.users.forEach((payloadUser) => {
                const userExists = action.users.some((actionUser) => {
                  return (
                    actionUser.email === payloadUser.email &&
                    actionUser.job_description ===
                      payloadUser.job_description &&
                    actionUser.level_name === payloadUser.level_name
                  );
                });

                if (!userExists) {
                  const orgMember = this.orgMembers.find(
                    (member) => member.email === payloadUser.email
                  );
                  const newUser = {
                    ...payloadUser,
                    firstname: orgMember ? orgMember.first_name : "",
                    lastname: orgMember ? orgMember.last_name : "",
                    can_delete_action: true,
                    can_edit_percentage: true,
                    can_edit_plan: true,
                  };
                  action.users.push(newUser);
                }
              });

              action.users = action.users.filter((actionUser) =>
                payload.users.some(
                  (payloadUser) =>
                    payloadUser.email === actionUser.email &&
                    payloadUser.job_description ===
                      actionUser.job_description &&
                    payloadUser.level_name === actionUser.level_name
                )
              );
            }
          });
        });
      });
    },
    clearAllSelected() {
      this.selectedMembers.clear();
      this.selectedLevels.clear();
      this.isDataReady = false;
      this.listActionsForSelectedMembers();
    },
    memberSelected(member, levelName, isInitialMember) {
      if (!isInitialMember) {
        this.selectedMembers.clear();
      }
      this.selectedLevels.clear();
      // const memberIdentifier = `${member.email} - ${levelName} - ${member.job_description}`;

      const memberIdentifier = `${member.email} - ${levelName} - ${member.job_description} - ${member.firstname} ${member.lastname}`;
      if (!this.selectedMembers.has(memberIdentifier)) {
        this.selectedMembers.add(memberIdentifier);
      }

      if (!this.isInitialSelectionDone) {
        this.initialMembers.add(memberIdentifier);
      }
      this.listActionsForSelectedMembers();
    },

    stopAdding() {
      this.gotAllInitialMembers = true;
    },
    memberDeselected(member, levelName) {
      this.selectedMembers.delete(
        `${member.email} - ${levelName} - ${member.job_description} - ${member.firstname} ${member.lastname} `
      );

      this.listActionsForSelectedMembers();
    },
    levelDeselected(level) {
      this.selectedLevels.delete(level);
      this.listActionsForLevels();
    },
    async levelSelected(newLevel) {
      this.selectedMembers.clear();
      this.selectedLevels.clear();

      this.selectedLevels.forEach((selectedLevel) => {
        if (this.isChildOf(selectedLevel, newLevel)) {
          this.selectedLevels.delete(selectedLevel);
        }
      });

      const childrenToRemove = new Set();
      this.selectedLevels.forEach((selectedLevel) => {
        if (this.isChildOf(newLevel, selectedLevel)) {
          childrenToRemove.add(selectedLevel);
        }
      });
      childrenToRemove.forEach((child) => this.selectedLevels.delete(child));

      if (!childrenToRemove.has(newLevel)) {
        this.selectedLevels.add(newLevel);
      }
      this.$store.commit("setExpandedLevels", this.selectedLevels);

      await this.listActionsForLevels();
    },

    isChildOf(parentLevel, childLevel) {
      let isChild = false;
      const checkChild = (level, target) => {
        if (level.children) {
          for (const child of level.children) {
            if (child.id === target.id) {
              isChild = true;
              return;
            }
            checkChild(child, target);
          }
        }
      };
      checkChild(parentLevel, childLevel);
      return isChild;
    },

    testKeyIncrease() {
      this.testKey += 1;
    },

    openDeleteModal(action) {
      if (action.can_delete_action) {
        this.selectedAction = action;
        this.showDeleteActionModal = true;
      }
    },
    openEditModal(phase, category, action) {
      if (action.can_edit_action) {
        this.selectedPhase = phase.phase_name;
        this.selectedCategory = category.category_name;

        this.selectedAction = action;
        this.showEditActionModal = true;
      }
    },

    reorderTableItems(tableItems, newOrder) {
      function reorderCategories(categories, order) {
        return order
          .map((o) =>
            categories.find((c) => c.category_name === o.category_name)
          )
          .filter(Boolean);
      }

      const reorderedPhases = newOrder
        .map((o) =>
          tableItems.find((phase) => phase.phase_name === o.phase_name)
        )
        .filter(Boolean);

      reorderedPhases.forEach((phase) => {
        const orderForPhase = newOrder.find(
          (o) => o.phase_name === phase.phase_name
        ).categories;
        phase.categories = reorderCategories(phase.categories, orderForPhase);
      });
      return reorderedPhases;
    },

    listActionsForLevels(orderType) {
      this.multipleMembersSelected = true;
      let uniqueUserCombinations = new Set();
      let selectedLevelUserIds = new Set();

      function createUserIdentifier(email, levelName, jobDescription) {
        return `${email}|${levelName}|${jobDescription}`;
      }

      const collectUserCombinations = (level) => {
        if (level && Array.isArray(level.members)) {
          level.members.forEach((member) => {
            let combination = createUserIdentifier(
              member.email,
              level.level_name,
              member.job_description
            );
            uniqueUserCombinations.add(combination);
            selectedLevelUserIds.add(combination);
          });
        }
        if (level && Array.isArray(level.children)) {
          level.children.forEach((child) => collectUserCombinations(child));
        }
      };

      this.selectedLevels.forEach((level) => {
        collectUserCombinations(level);
      });
      const filteredPhases = this.allActions
        .map((phase) => {
          let newPhase = JSON.parse(JSON.stringify(phase));
          newPhase.categories = newPhase.categories
            .map((category) => {
              let newCategory = { ...category };
              newCategory.actions = category.actions.filter((action) => {
                let uniqueUsers = new Set();
                let filteredUsers = action.users.filter((user) => {
                  let identifier = createUserIdentifier(
                    user.email,
                    user.level_name,
                    user.job_description
                  );
                  if (
                    uniqueUserCombinations.has(identifier) &&
                    !uniqueUsers.has(identifier)
                  ) {
                    uniqueUsers.add(identifier);
                    return true;
                  }
                  return false;
                });
                return filteredUsers.length > 0;
              });
              return newCategory;
            })
            .filter((category) => category.actions.length > 0);
          return newPhase;
        })
        .filter((phase) => phase.categories.length > 0);

      this.tableItems = filteredPhases;
      this.isLoadingData = false;
    },
    async listActionsForSelectedMembers(orderType) {
      if (this.allActions.length > 0) {
        this.multipleMembersSelected = this.selectedMembers.size > 1;
        const uniquePhases = new Set();

        const parseMemberString = (memberString) => {
          const parts = memberString.split(" - ");
          return {
            email: parts[0],
            levelName: parts[1],
            jobDescription: parts[2],
          };
        };
        let selectedMembersDetails = Array.from(this.selectedMembers).map(
          parseMemberString
        );
        selectedMembersDetails = selectedMembersDetails.filter((m) => m);
        this.allActions.forEach((originalPhase) => {
          let phaseAdded = false;
          let newPhase = JSON.parse(JSON.stringify(originalPhase));

          newPhase.categories = newPhase.categories.map((category) => {
            let newCategory = { ...category };
            newCategory.actions = category.actions.filter((action) => {
              return action.users.some((user) =>
                selectedMembersDetails.some(
                  (member) =>
                    member.email === user.email &&
                    member.levelName === user.level_name &&
                    member.jobDescription === user.job_description
                )
              );
            });
            return newCategory;
          });
          newPhase.categories = newPhase.categories.filter(
            (category) => category.actions.length > 0
          );

          newPhase.categories.forEach((category) => {
            if (category.actions.length > 0 && !phaseAdded) {
              uniquePhases.add(newPhase);
              phaseAdded = true;
            }
          });
        });
        const phasesArray = Array.from(uniquePhases);
        this.tableItems = phasesArray;
        this.isLoadingData = false;
      } else {
        this.tableItems = [];
        this.isLoadingData = false;
      }
    },
    async getAllActionsList() {
      const payload = {
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        role: null,
        user: [],
        view: "Grouped",
      };

      const { data } = await ActionsService.list_actions(payload);
      this.allActions = data.data;
      this.actionsPermissions = this.userStore.currentPermissions[0].find(
        (p) => p.name === "Actions"
      ).permissions;
      const viewAllPlan = this.doesHaveRight("viewAllPlan");
      const viewTeamPlan = this.doesHaveRight("viewTeamPlan");
      const viewOwnPlan = this.doesHaveRight("viewOwnPlan");
      this.userCanAddActionForAll = this.doesHaveRight("addAllAction");
      this.userCanAddTeamActionOnly =
        !this.userCanAddActionForAll && this.doesHaveRight("addTeamAction");
      this.userCanAddOwnActionOnly =
        !this.userCanAddActionForAll &&
        !this.userCanAddTeamActionOnly &&
        this.doesHaveRight("addOwnAction");
      const { level_name } = this.userData.current_job_description;

      if (!viewAllPlan) {
        let filteredActions = [];
        this.allActions.forEach((phase) => {
          phase.categories.forEach((category) => {
            const filteredCategoryActions = category.actions.filter(
              (action) => {
                if (viewTeamPlan) {
                  return action.users.some(
                    (user) => user.level_name === level_name
                  );
                }
                if (viewOwnPlan) {
                  return action.users.some(
                    (user) => user.email === this.userData.email
                  );
                }
                return false;
              }
            );

            if (filteredCategoryActions.length > 0) {
              filteredActions.push({
                ...phase,
                categories: [{ ...category, actions: filteredCategoryActions }],
              });
            }
          });
        });
        this.allActions = filteredActions;
      }

      this.phaseOptions = data.data
        .map((phase) => phase.phase_name)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    },
    formatAdvancement(value) {
      if (Number.isInteger(value)) {
        return value.toString();
      } else {
        return value.toFixed(1);
      }
    },
    toggleSort(field, phaseIndex, categoryIndex, sortOrder) {
      const actionsArray =
        this.tableItems[phaseIndex].categories[categoryIndex].actions;
      if (actionsArray && actionsArray.length > 1) {
        actionsArray.sort((a, b) => {
          if (field === "deadline") {
            const parseDate = (dateString) => {
              const isDateFormat1 = dateString.includes("/");
              const isDateFormat2 = dateString.includes("-");

              let date;
              if (isDateFormat1) {
                const parts = dateString.split("/");
                date = new Date(parts[2], parts[1] - 1, parts[0]);
              } else if (isDateFormat2) {
                date = new Date(dateString);
              } else {
                console.error("Unsupported date format");
                return null;
              }

              return date;
            };

            const dateA = parseDate(a.action_details[field]);
            const dateB = parseDate(b.action_details[field]);

            if (
              !dateA ||
              !dateB ||
              isNaN(dateA.getTime()) ||
              isNaN(dateB.getTime())
            ) {
              console.error("Invalid date encountered");
              return 0;
            }

            if (sortOrder === "asc") {
              this.sortTypes[field] = "desc";
              return dateA - dateB;
            } else {
              this.sortTypes[field] = "asc";
              return dateB - dateA;
            }
          } else if (field === "award") {
            const numA = parseFloat(a.action_details[field]);
            const numB = parseFloat(b.action_details[field]);

            if (sortOrder === "asc") {
              this.sortTypes[field] = "desc";
              return numA - numB;
            } else {
              this.sortTypes[field] = "asc";
              return numB - numA;
            }
          } else if (field === "advancement") {
            const numA = parseFloat(a.average_advancement);
            const numB = parseFloat(b.average_advancement);

            if (sortOrder === "asc") {
              this.sortTypes[field] = "desc";
              return numA - numB;
            } else {
              this.sortTypes[field] = "asc";
              return numB - numA;
            }
          } else if (field === "action" || field == "subject") {
            const actionA = a.action_details[field].toLowerCase();
            const actionB = b.action_details[field].toLowerCase();

            if (sortOrder === "asc") {
              this.sortTypes[field] = "desc";
              return actionA.localeCompare(actionB);
            } else {
              this.sortTypes[field] = "asc";
              return actionB.localeCompare(actionA);
            }
          } else if (field === "id") {
            const numA = parseInt(a.action_details[field].split(".").pop());
            const numB = parseInt(b.action_details[field].split(".").pop());

            if (sortOrder === "asc") {
              this.sortTypes[field] = "desc";
              return numA - numB;
            } else {
              this.sortTypes[field] = "asc";
              return numB - numA;
            }
          } else {
            const valueA = a.action_details[field];
            const valueB = b.action_details[field];

            this.sortTypes[field] =
              this.sortTypes[field] === "asc" ? "desc" : "asc";

            return sortOrder === "asc" ? valueA - valueB : valueB - valueA;
          }
        });
      } else {
      }
    },
    async getOrgMembers() {
      try {
        const { data } = await OrganizationsService.list_org_members(
          {
            organization_name:
              this.currentOrganization.name ||
              this.currentOrganization.organization ||
              this.currentOrganization,
            project_name: this.currentProject.name,
          }
          // "organization"
          // "Actions"
        );
        const neededOrganization = data.data;
        this.usersModal = data.filtered_for_modal;
        this.orgMembers = neededOrganization.map((item) => {
          return {
            first_name: item.firstname,
            last_name: item.lastname,
            email: item.email,
            roles: item.org_roles,
            advancement: item.avgAdvancement,
          };
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    async getOrgData() {
      const { data } =
        await OrganizationsService.list_organizations_and_projects(
          "organization"
        );
      const neededOrganization = data.data.find((org) => {
        return (
          org.organization ===
            (this.currentOrganization.organization ||
              this.currentOrganization.name ||
              this.currentOrganization) &&
          org.projects[0].name === this.currentProject.name
        );
      });

      if (neededOrganization) {
        this.orgsWithProjects = data.data;
        this.neededOrganization = neededOrganization.tree;
        const neededProjectOfOrganization = neededOrganization.projects[0].tree;
        const setProjectLevelFlag = (projectLevels, isRootLevel = true) => {
          projectLevels.forEach((projectLevel) => {
            projectLevel.isProjectLevel = true;
            if (isRootLevel) {
              this.neededOrganization.push(projectLevel);
            }
            if (projectLevel.children && projectLevel.children.length > 0) {
              setProjectLevelFlag(projectLevel.children, false); // Not root level anymore
            }
          });
        };

        setProjectLevelFlag(neededProjectOfOrganization);
        function collectLevelObjects(node, levels = []) {
          if (node && Array.isArray(node)) {
            node.forEach((child) => {
              if (child.level_name && child.id) {
                levels.push({
                  value: child.level_name,
                  label: child.level_name,
                });
              }
              if (child.children && Array.isArray(child.children)) {
                collectLevelObjects(child.children, levels);
              }
            });
          }
          return levels;
        }

        this.selectedProject = neededOrganization.projects[0].name;
        this.allOrgLevelsOptions = collectLevelObjects(neededOrganization.tree);
      }
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption() {
      this.selectedOption = options;
      this.isOpen = false;
    },

    toggleChevron(index, phaseIndex, categoryIndex) {
      if (
        this.isDetailsOpen &&
        index === this.selectedRowIndex &&
        phaseIndex === this.selectedPhaseIndex &&
        categoryIndex === this.selectedCategoryIndex
      ) {
        this.isDetailsOpen = false;
        this.selectedRowIndex = null;
        this.selectedCategoryIndex = null;
        this.selectedPhaseIndex = null;
      } else {
        this.isDetailsOpen = true;
        this.selectedRowIndex = index;
        this.selectedCategoryIndex = categoryIndex;
        this.selectedPhaseIndex = phaseIndex;
      }
    },
    openIndividualAction() {
      this.showIndividualAction = true;
      this.isOpen = !this.isOpen;
    },
    closeIndividualAction() {
      this.showIndividualAction = false;
      this.resetForm();
    },
    openMultipleActions() {
      this.showMultipleActions = true;
    },
    closeMultipleActions() {
      this.showMultipleActions = false;
    },

    switchView(view) {
      this.currentView = view;
    },
    async updateAdvancementMultiple(
      item,
      advancement,
      phase,
      category,
      action,
      job_description,
      level_name,
      email
    ) {
      const { data, res } = await ActionsService.update_advancement({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        phase: phase,
        category: category,
        action: action,
        project: this.currentProject.name,
        email: email,
        advancement: Math.min(Math.max(advancement, 0), 100),
        job_description: job_description,
        level_name: level_name,
        _id: item.action_details._id,
      });

      this.noReset = true;
      this.globalStore.setUpdatedProgress(true);
      await this.getAllActionsList();
      await this.getOrgData();
      // this.something = true;
      if (this.selectedMembers.size > 0) {
        await this.listActionsForSelectedMembers();
      }
      if (this.selectedLevels.size > 0) {
        await this.listActionsForLevels();
      }

      // this.something = true;
    },
    resetSomething() {
      this.something = false;
    },
    async updateAdvancement(
      item,
      advancement,
      sentPhase,
      sentCategory,
      sentAction
    ) {
      const matchingUser = await this.findMatchingUser(item);

      this.tableItems.forEach((phase) => {
        if (phase.phase_name === sentPhase) {
          const neededPhase = phase;
          neededPhase.categories.forEach((category) => {
            if (category.category_name === sentCategory) {
              const neededCategory = category;
              neededCategory.actions.forEach((action) => {
                if (action.action_details.id === item.action_details.id) {
                  const neededAction = action;
                  neededAction.users.forEach((user) => {
                    if (user.email === matchingUser.email) {
                      user.advancement = advancement;
                    }
                  });
                }
              });
            }
          });
        }
      });
      const { data, res } = await ActionsService.update_advancement({
        phase: sentPhase,
        category: sentCategory,
        action: sentAction,
        project: this.currentProject.name,
        email: matchingUser.email,
        advancement: Math.min(Math.max(advancement, 0), 100),
        job_description: matchingUser.job_description,
        level_name: matchingUser.level_name,
        _id: item.action_details._id,
      });

      if (!data.success) {
        Message.error(this.$t("Error updating advancement!"));
      } else {
        updateMyData();
        this.noReset = true;
        this.globalStore.setUpdatedProgress(true);
        await this.getAllActionsList();
        await this.getOrgData();
        // this.something = true;
        if (this.selectedMembers.size > 0) {
          await this.listActionsForSelectedMembers();
        }
        if (this.selectedLevels.size > 0) {
          await this.listActionsForLevels();
        }
      }
    },
    calculateUserActionCountAndAverage(matchingUser, array) {
      let totalAdvancement = 0,
        actionCount = 0;
      const email = matchingUser.email;
      const levelName = matchingUser.level_name;
      const jobDescription = matchingUser.job_description;
      array.forEach((phase) => {
        phase.categories.forEach((category) => {
          category.actions.forEach((action) => {
            action.users.forEach((user) => {
              if (
                user.email === email &&
                user.level_name === levelName &&
                user.job_description === jobDescription
              ) {
                totalAdvancement += Number(user.advancement);
                actionCount++;
              }
            });
          });
        });
      });

      return {
        count: actionCount,
        average: actionCount > 0 ? totalAdvancement / actionCount : 0,
      };
    },

    updateOrgMemberAdvancement(matchingUser, averageAdvancement) {
      const member = this.orgMembers.find(
        (member) => member.email === matchingUser.email
      );
      if (member) {
        const role = member.roles.find(
          (role) =>
            role.level_name === matchingUser.level_name &&
            role.job_description === matchingUser.job_description
        );
        if (role) {
          role.avgAdvancement = averageAdvancement;
        }
      }
    },
    updateAdvancementInArray(
      array,
      phase,
      category,
      action,
      email,
      advancement
    ) {
      const phaseObj = array.find((p) => p.phase_name === phase);
      if (!phaseObj) {
        return "Phase not found";
      }
      const categoryObj = phaseObj.categories.find(
        (c) => c.category_name === category
      );
      if (!categoryObj) {
        return "Category not found";
      }

      const actionObj = categoryObj.actions.find(
        (a) => a.action_details.action === action
      );
      if (!actionObj) {
        return "Action not found";
      }
      const userObj = actionObj.users.find((u) => u.email === email);
      if (!userObj) {
        return "User not found";
      }

      userObj.advancement = Math.min(Math.max(advancement, 0), 100);

      let totalAdvancement = 0;
      actionObj.users.forEach((user) => {
        totalAdvancement += user.advancement;
      });

      actionObj.average_advancement = totalAdvancement / actionObj.users.length;

      return "Success";
    },
    async getTemplate() {
      await ActionsService.bulk_actions_template({
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization ||
          this.currentOrganization,
        project: this.currentProject.name,
      });
    },
    getFormattedDate(date) {
      if (!date || date.toLowerCase().includes("nan")) {
        return "Invalid date";
      }

      const parsedDate = new Date(date);

      if (isNaN(parsedDate.getTime())) {
        return "Could not parse date";
      }

      const day = parsedDate.getDate().toString().padStart(2, "0");
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const year = parsedDate.getFullYear();

      return `${day}-${month}-${year}`;
    },

    parseUserString(userString) {
      const emailRegex = /([^ ]+@[^ ]+)/;
      const levelRegex = /\(([^-]+) -/;
      const jobDescriptionRegex = /- (.+)\)/;

      return {
        email: emailRegex.exec(userString)?.[1] || "",
        level_name: levelRegex.exec(userString)?.[1].trim() || "",
        job_description: jobDescriptionRegex.exec(userString)?.[1].trim() || "",
      };
    },
    async addAction(actionDetails) {
      const actionData = {
        title: actionDetails.action,
        actionData: {
          organization:
            this.currentOrganization.name ||
            this.currentOrganization.organization ||
            this.currentOrganization,
          project: this.currentProject.name,
          phase: actionDetails.phase,
          category: actionDetails.category,
          users: actionDetails.users,
          levels: actionDetails.levels,
          roles: actionDetails.roles,
          action: actionDetails.action,
          subject: actionDetails.subject,
          deadline: actionDetails.dueDate,
          progress: actionDetails.progress,
          advancement: 0,
          award: actionDetails.award,
        },
      };
      const { data, res } = await ActionsService.create_action(actionData);
      if (!data.success) {
        Message.error(data.message);
      } else {
        Message.success(data.message);
        updateMyData();
        this.noReset = true;
        await this.getAllActionsList();
        await this.getOrgData();
        if (this.selectedMembers.size > 0) {
          await this.listActionsForSelectedMembers();
        }
        if (this.selectedLevels.size > 0) {
          await this.listActionsForLevels();
        }
        this.something = true;
        this.closeIndividualAction();

        this.resetForm();
      }
    },
    resetForm() {
      this.phase = "";
      this.category = "";
      this.role = "";
      this.selectedUsers = [];
      this.actionTitle = "";
      this.subjectText = "";
      this.dueDate = "";
      this.points = "";
      this.progress = "";
    },
    async refreshEverything() {
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      // this.isLoadingRibbonData = true;
      this.isLoadingData = true;
      this.isDataReady = false;
      this.tableItems = [];
      this.allActions = [];
      this.selectedLevels.clear();
      this.selectedMembers.clear();
      await this.getMembersAvatars();
      await this.getOrgMembers();
      await this.getOrgData();
      await this.getAllActionsList();
      await this.listActionsForSelectedMembers();
      this.updateModalOptions();

      this.isLoadingData = false;
      this.isDataReady = true;
      this.isLoadingRibbonData = false;
      loading.close();
    },

    async handleUpload(file, fileId) {
      let message = null;
      const loading = ElLoading.service({
        fullscreen: true,
        background: "#0a1f8f45",
      });
      try {
        await FilesService.uploadFile(file, fileId);
      } catch (error) {}
      try {
        const { data, res } = await ActionsService.bulk_create_actions({
          file_name: file.name,
          project_name: this.currentProject.name,
        });
        if (!data.success) {
          showMessage("error", "Error creating bulk actions: " + data.detail);
        } else {
          updateMyData();
          this.closeMultipleActions();
          this.something = true;
          await this.refreshEverything();
          showMessage("success", "File Uploaded Succesfuly ");
        }
      } catch (e) {
        message = e;
        showMessage("success", message);
      }
      loading.close();
    },

    updatePhase(val) {
      this.phase = val;

      this.categoryOptions = this.allActions
        .flatMap((item) => {
          if (item.phase_name === this.phase) {
            return item.categories.map((category) => ({
              code: category.category_name,
              name: category.category_name,
            }));
          } else {
            return [];
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name.toLowerCase()));
    },
    updateCategory(val) {
      this.category = val;
    },
    updateRole(val) {
      this.role = val;
    },
    // updateUsers(val) {
    //   this.selectedUsers = val;
    // },
    updateActionTitle(val) {
      this.actionTitle = val;
    },
    updateSubject(val) {
      this.subjectText = val;
    },
    updateDueDate(val) {
      this.dueDate = val;
    },
    updateProgress(val) {
      this.progress = val;
    },
    togglePhase(phaseIndex) {
      if (this.expandedPhases.has(phaseIndex)) {
        this.expandedPhases.delete(phaseIndex);
        this.tableItems[phaseIndex].categories.forEach((_, categoryIndex) => {
          this.expandedCategories.delete(`${phaseIndex}-${categoryIndex}`);
        });
      } else {
        this.expandedPhases.add(phaseIndex);
        this.tableItems[phaseIndex].categories.forEach((_, categoryIndex) => {
          this.expandedCategories.add(`${phaseIndex}-${categoryIndex}`);
        });
      }
    },
    toggleCategory(phaseIndex, categoryIndex) {
      const key = `${phaseIndex}-${categoryIndex}`;
      if (this.expandedCategories.has(key)) {
        this.expandedCategories.delete(key);
      } else {
        this.expandedCategories.add(key);
      }
    },
    toggleRow(phaseIndex, categoryIndex, rowIndex) {
      const key = `${phaseIndex}-${categoryIndex}-${rowIndex}`;
      if (this.expandedRows.has(key)) {
        this.expandedRows.delete(key);
      } else {
        this.expandedRows.add(key);
      }
    },
    isCategoryExpanded(phaseIndex, categoryIndex) {
      return this.expandedCategories.has(`${phaseIndex}-${categoryIndex}`);
    },
    isPhaseExpanded(phaseIndex) {
      return this.expandedPhases.has(phaseIndex);
    },
    phaseDirection(phaseIndex) {
      return this.downPhases.has(phaseIndex);
    },
    categoryDirection(categoryIndex) {
      return this.downCategories.has(categoryIndex);
    },
    setCountry(country) {
      this.selectedLanguage = country.label;
      this.selectedLanguageValue = country.value;
      this.hovered = !this.hovered;
      this.changedLanguage = true;
    },
    toggleCountry(countryIndex) {
      if (this.expandedCountries.has(countryIndex)) {
        this.expandedCountries.delete(countryIndex);
      } else {
        this.expandedCountries.add(countryIndex);
      }
    },
    toggleDirection(countryIndex, directionIndex) {
      const key = `${countryIndex}-${directionIndex}`;
      if (this.expandedDirections.has(key)) {
        this.expandedDirections.delete(key);
      } else {
        this.expandedDirections.add(key);
      }
    },
    toggleJob(countryIndex, directionIndex, jobIndex) {
      const key = `${countryIndex}-${directionIndex}-${jobIndex}`;
      if (this.expandedJobs.has(key)) {
        this.expandedJobs.delete(key);
      } else {
        this.expandedJobs.add(key);
      }
    },

    isCountryExpanded(countryIndex) {
      return this.expandedCountries.has(countryIndex);
    },
    isDirectionExpanded(countryIndex, directionIndex) {
      return this.expandedDirections.has(`${countryIndex}-${directionIndex}`);
    },
    isJobExpanded(countryIndex, directionIndex, jobIndex) {
      return this.expandedJobs.has(
        `${countryIndex}-${directionIndex}-${jobIndex}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.fullActionPage {
  min-height: calc(100vh - 100px);
  max-height: calc(100vh - 100px);
  overflow: auto;
}
.plan {
  margin-bottom: 10px;
  background: white;
}

.table thead {
  border-bottom: 1px solid #eaeaea;
}

.table {
  table-layout: fixed;
  width: 100%;
  background: white;
}

.content {
  overflow-y: hidden;
}

.mobile-topfilters {
  display: none;
}
.action-content {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.table-content {
  overflow: auto;
  margin-top: 30px;
  max-height: calc(100vh - 200px);
  padding: 0;
}
@media (max-width: 970px) {
  .fullActionPage {
    margin-top: 50px;
  }
  .action-content {
    flex-direction: column;
    gap: 10px;
    overflow: auto;
  }
}
@media (max-width: 650px) {
  .fullActionPage {
    margin-top: 0;
  }
  .action-content {
    flex-direction: column;
    gap: 10px;
  }
}

.export_btn {
  border-radius: 0px 0px 0px 25px;
  padding: 8px 20px;
}

.tableContainer {
  height: calc(100vh - 207px);
}
.table thead th {
  background: none;
}
.table th,
.table td {
  border-top: none;
}
.action-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px;
}
.org-select {
  margin-left: 16px;
}

.dropdown-toggle:not(.dropdown-dark):not(.dropdown-white) {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 0px 0px 0px 25px;
  padding: 8px 20px;
}


.expanded {
  height: calc(100% - 200px) !important;
}

.multiselect__select {
  display: none;
}

.multiselect .multiselect__tags {
  border-radius: 4px !important;
  border-color: #ccc !important;
  min-height: 35px !important;
}

.multiselect .multiselect__content-wrapper {
  border-color: #ccc !important;
  width: 212px;
  border-radius: 4px !important;
}
.table tbody + tbody {
  border-top: none;
}
.vue-treeselect {
  width: 212px;
}

.options-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-btn {
  border-radius: 0px 0px 0px 25px;
}

.blue {
  color: #0071ce;
}
.img {
  margin-top: -2px;
  width: 12px;
  height: 12px;
}

.changeOrderBtn {
  width: max-content;
  border-radius: 0px 0px 0px 25px;
  background: white;
  border: 1px solid rgb(195, 195, 195);
}
.changeOrderBtn:hover {
  background: #eaeaea;
}

.topStyles {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
}
.topStyles-mobile {
  display: none;
}
@media (max-width: 1245px) {
  .topStyles h1 {
    font-size: 20px;
  }
  .progress_bar {
    margin-bottom: 9px;
  }
}
@media (max-width: 1096px) {
  .topStyles {
    display: none;
  }
  .topStyles-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 15px;
  }
  .topStyles-mobile h1 {
    font-size: 16px;
  }
  .table-content {
    margin-top: 15px;
    height: 600px;
    overflow: auto;
  }
  .action-content {
    // height: calc(100vh - 300px);
    margin-bottom: 50px;
    overflow: auto;
  }
  .mainAreaContent {
    overflow: hidden;
  }
}
@media (max-width: 970px) {
}
@media (max-width: 712px) {
  .topStyles-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .button-container {
    /* Assuming .button-container is the class for the div wrapping your buttons */
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}

@media (max-width: 876px) {
  .progress_bar {
    margin-bottom: 10px;
  }
}

.exportBtn {
  border: 1px solid rgb(195, 195, 195);
  background: white;
  width: 40px;
  height: 40px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.exportBtn:hover {
  background: #eaeaea;
}
.el-table--enable-row-transition .el-table__body td.el-table__cell,
table tbody td {
  background: none;
}
</style>
