<template>
  <div>
    <CModal @hide="close" :show.sync="show" alignment="center">
      <template v-slot:header>
        <h3 style="padding-left: 1rem">{{ $t("Edit Action") }}</h3>
        <CButton @click="close">
          <i class="fa fa-times"></i>
        </CButton>
      </template>

      <div class="modal-body editAction" style="margin-bottom: 3px">
        <div
          v-if="isGroupedView"
          style="
            margin-top: 10px;
            width: 100%;
            background: #f7fafe;
            border: 1px solid #9ec2f1;
            padding: 20px;
            margin-bottom: 10px;
          "
        >
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            "
          >
            <div
              style="
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
              "
            >
              <label
                class="filter_btn"
                :class="{ selected: selectedFilter === 'users' }"
                @click="filterByUsers"
                >{{ $t("Users") }}</label
              >
              <label
                class="filter_btn"
                :class="{ selected: selectedFilter === 'levels' }"
                @click="filterByLevels"
                >{{ $t("Levels") }}</label
              >
              <label
                class="filter_btn"
                :class="{ selected: selectedFilter === 'roles' }"
                @click="filterByJobDescription"
                >{{ $t("Roles") }}</label
              >
            </div>
          </div>
          <div v-if="selectedFilter === 'users'">
            <label for="users">{{ $t("Users") }}</label>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedCurrentUsers"
                :data="scopeSelectedUsersOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Current Selection')"
              />
            </div>
          </div>
          <div v-else-if="selectedFilter === 'levels'">
            <label for="levels">{{ $t("Levels") }}</label>
            <div>
              <el-tree-select
                filterable
                style="width: 100%"
                v-model="selectedCurrentLevels"
                :data="allOrgLevelsOptions"
                multiple
                :render-after-expand="false"
                show-checkbox
                check-strictly
                check-on-click-node
                :disabled="selectAll"
                :placeholder="$t('Current Selection')"
              />
            </div>
          </div>
          <div v-else-if="selectedFilter === 'roles'">
            <label for="roles">{{ $t("Roles") }}</label>
            <el-tree-select
              :disabled="!isGroupedView || selectAll"
              filterable
              style="width: 100%"
              v-model="selectedRoles"
              :data="globalStore.scopeRolesOptions"
              multiple
              :render-after-expand="false"
              show-checkbox
              check-strictly
              check-on-click-node
              :placeholder="$t('Select Role')"
            />
          </div>
        </div>
        <div class="d-flex" style="justify-content: space-between">
          <div style="width: 49%">
            <label for="phase">{{ $t("Phase") }}</label>
            <!-- <v-select
              :disabled="true"
              class="style-chooser"
              v-model="phase"
              :options="phaseOptions.map((item) => item.code)"
              label="text"
              track-by="value"
              placeholder="Select Phase"
              taggable
              push-tags
            ></v-select> -->
            <el-select
              :disabled="true"
              class="style-chooser"
              v-model="phase"
              filterable
              allow-create
              label="text"
              track-by="value"
            >
              <el-option
                v-for="item in phaseOptions.map((el) => ({
                  value: el.code,
                  label: el.code,
                  id: el.code,
                }))"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            /></el-select>
          </div>
          <div style="width: 49%">
            <label for="category">{{ $t("Category") }}</label>
            <!-- <v-select
              :disabled="true"
              class="style-chooser"
              v-model="category"
              :options="categoryOptions.map((item) => item.code)"
              label="text"
              track-by="value"
              placeholder="Select Category"
              taggable
              push-tags
            ></v-select> -->
            <el-select
              :disabled="true"
              class="style-chooser"
              v-model="category"
              filterable
              allow-create
              label="text"
              track-by="value"
            >
              <el-option
                v-for="item in categoryOptions.map((el) => ({
                  value: el.code,
                  label: el.code,
                  id: el.code,
                }))"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            /></el-select>
          </div>
        </div>
        <div style="margin-top: 10px">
          <label for="actionTitle">{{ $t("Action Title") }}</label>
          <!-- <CInput
            id="actionTitle"
            v-model="actionTitle"
            data-testid="EditedactionTitle"
          /> -->
          <el-input
            id="actionTitle"
            v-model="actionTitle"
            :placeholder="$t('Action Title')"
          />
        </div>
        <div style="margin-top: 10px">
          <label for="subjectText"
            >{{ $t("Subject") }}<span style="color: #0071ce"> *</span></label
          >
          <!-- <CTextarea
            id="subjectText"
            v-model="subjectText"
            :disabled="!isGroupedView && !changedTitle && isOneUserInAction"
          /> -->
          <el-input
            :disabled="!isGroupedView && !changedTitle && isOneUserInAction"
            id="subjectText"
            v-model="subjectText"
            style="height: 80px"
            :rows="4"
            type="textarea"
            :placeholder="$t('Action description')"
          />
        </div>
        <div class="d-flex" style="margin-top: 20px">
          <div style="flex: 1">
            <div>
              <label for="dueDate"
                >{{ $t("Due Date")
                }}<span style="color: #0071ce"> *</span></label
              >
            </div>
            <!-- <CInput
              id="dueDate"
              type="date"
              v-model="dueDate"
              :disabled="!isGroupedView && !changedTitle && isOneUserInAction"
            /> -->
            <el-date-picker
              v-model="dueDate"
              id="dueDate"
              type="date"
              placeholder="Due Date"
              :size="size"
              style="width: 100%"
              format="DD/MM/YYYY"
              :disabled="!isGroupedView && !changedTitle && isOneUserInAction"
            />
          </div>
          <div style="flex: 1; margin-left: 10px" v-if="!isGroupedView">
            <label for="points">{{ $t("Points") }}</label>
            <!-- <CInput
              id="points"
              :modelValue="points"
              @update:modelValue="points = $event"
              type="number"
              max="1000"
              min="0"
            /> -->
            <el-input-number
              id="points"
              v-model="points"
              :min="1"
              :max="1000"
              @change="points = $event"
              style="width: 100%"
            />
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div
          class="d-flex justify-content-between w-100 align-items-center"
          :class="isGroupedView ? 'GroupedFooter' : 'NotGroupedFooter'"
        >
          <div style="left: 0; font-size: 12px; color: #0071ce">
            <i>* {{ $t("Edit title to enable") }}</i>
          </div>
          <div>
            <CButton @click="close" class="link">{{ $t("Cancel") }}</CButton>
            <CButton
              @click="editAction"
              color="primary"
              class="modal-confirm"
              data-testid="saveEditedAction"
              :disabled="isSaveDisabled"
            >
              {{ $t("Save") }}
            </CButton>
          </div>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Treeselect from "vue3-treeselect";
import "vue3-treeselect/dist/vue3-treeselect.css";
import "vue-select/dist/vue-select.css";
import { ActionsService } from "@/services/actions.js";
import { showMessage } from "../../../utils/helpers";
import { useGlobalStore } from "@/stores/store";
import { mapState } from "vuex";

export default {
  name: "EditActionModal",
  data() {
    return {
      globalStore: useGlobalStore(),
      selectedFilter: "users",
      currentKey: 0,
      key: 1,
      phase: "",
      category: "",
      role: "",
      selectedUsers: [],
      actionTitle: "",
      subjectText: "",
      dueDate: "",
      points: 100,
      enableCategoryDropDown: false,
      selectedRoles: [],
      changedTitle: false,
      selectedCurrentUsers: [],
      selectedCurrentLevels: [],
      selectAll: false,
    };
  },
  components: {
    vSelect,
    Treeselect,
  },
  computed: {
    ...mapState({
      selectedEvent: (state) => state.selectedEvent,
      selectedTraining: (state) => state.selectedTraining,
      userData: (state) => state.userData,
      currentOrganization: (state) => state.selectedOrg,
      currentProject: (state) => state.selectedProject,
      includedOrgs: (state) => state.includedOrgs,
      allOrgs: (state) => state.allOrgs,
    }),
    isSaveDisabled() {
      return !(
        this.phase &&
        this.category &&
        (this.selectAll ||
          this.selectedUsers.length > 0 ||
          this.selectedCurrentUser.length > 0 ||
          this.selectedCurrentLevel.length > 0 ||
          this.selectedOtherLevels.length > 0 ||
          this.selectedCurrentJobDescription.length > 0 ||
          this.selectedOtherJobDescriptions.length > 0) &&
        this.actionTitle.trim() &&
        this.subjectText.trim() &&
        this.dueDate &&
        this.points
      );
    },
    isOneUserInAction() {
      if (this.selectedAction && this.selectedAction.users.length === 1) {
        return false;
      } else {
        return true;
      }
    },
    membersOptions() {
      const options = this.orgMembers.flatMap((member) => {
        return member.roles.map((role) => {
          return {
            id: `${member.email} (${role.level_name} - ${role.job_description})`,
            label: `${member.first_name} ${member.last_name} (${role.level_name} - ${role.job_description})`,
          };
        });
      });
      options.sort((a, b) => a.label.localeCompare(b.label));

      return options;
    },
  },
  props: {
    show: Boolean,
    phaseOptions: Array,
    categoryOptions: Array,
    usersOptions: Array,
    orgMembers: Array,
    selectedAction: Object,
    selectedPhase: String,
    selectedCategory: String,
    listOfMembers: Array,
    usersModal: Array,
    isGroupedView: Boolean,
    selectedMembers: Set,
    preSelectedUsers: Array,
    otherUsers: Array,
    preSelectedLevels: Array,
    otherLevels: Array,
    scopeOtherUsersOptions: Array,
    scopeSelectedLevelOptions: Array,
    scopeOtherLevelsOptions: Array,
    allOrgLevelsOptions: Array,
    userToEdit: Set,
    userCanAddActionForAll: Boolean,
    userCanAddTeamActionOnly: Boolean,
    userCanAddOwnActionOnly: Boolean,
  },

  watch: {
    show: {
      handler: function (val, oldVal) {
        if (val) {
          this.scopeSelectedUsersOptions = this.orgMembers.flatMap((member) =>
            member.roles.map((role) => ({
              value: `${member.email} - ${role.level_name} - ${role.job_description}`,
              label: `${member.email} - ${role.level_name} - ${role.job_description}`,
            }))
          );

          this.selectedCurrentUsers = this.selectedAction.users.map(
            (user) =>
              `${user.email} - ${user.level_name} - ${user.job_description}`
          );
          this.currentKey++;
          this.selectedUsers = this.selectedAction.users
            .map((user) => {
              const member = this.orgMembers.find(
                (member) => member.email === user.email
              );
              if (member && member.roles.length > 0) {
                const role = member.roles.find((role) => {
                  return (
                    role.level_name === user.level_name &&
                    role.job_description === user.job_description
                  );
                });
                if (role) {
                  return `${member.email} (${role.level_name} - ${role.job_description})`;
                }
              }
              return undefined;
            })
            .filter(
              (value, index, self) =>
                value !== undefined && self.indexOf(value) === index
            );

          this.phase = this.selectedPhase;
          this.category = this.selectedCategory;
          this.actionTitle = this.selectedAction.action_details.action;
          this.subjectText = this.selectedAction.action_details.subject;
          this.points = this.selectedAction.action_details.award;
          const deadline = this.selectedAction.action_details.deadline;
          let formattedDueDate = "";

          if (deadline.includes("/")) {
            const [day, month, year] = deadline.split("/");
            formattedDueDate = `${year}-${month}-${day}`;
          } else if (deadline.includes("-")) {
            formattedDueDate = this.formatDate(deadline);
          }

          this.dueDate = formattedDueDate;
        }
      },
      deep: true,
    },
    actionTitle: {
      handler: function (val, oldVal) {
        if (val !== this.selectedAction.action_details.action) {
          this.changedTitle = true;
        } else {
          this.changedTitle = false;
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    filterByUsers() {
      this.selectedFilter = "users";
    },
    filterByLevels() {
      this.selectedFilter = "levels";
    },
    filterByJobDescription() {
      this.selectedFilter = "roles";
    },
    formatDate(inputDate) {
      const date = new Date(inputDate);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    async editAction() {
      const formattedDate = new Date(this.dueDate);
      const formattedDateString = formattedDate.toLocaleDateString("en-GB");
      const category = this.category;
      const phase = this.phase;
      let membersToSend = [];
      let selectedMember = {};
      if (!this.isGroupedView) {
        if (this.selectedUsers.length > 0) {
          membersToSend = this.selectedUsers.map((user) => {
            const [email, role] = user.split(" (");
            const [level_name, job_description] = role.split(" - ");
            const actionUser = this.selectedAction.users.find(
              (actionUser) =>
                actionUser.email === email &&
                actionUser.level_name === level_name &&
                actionUser.job_description === job_description.slice(0, -1)
            );
            return {
              email: email,
              level_name: level_name,
              job_description: job_description.slice(0, -1),
              advancement: actionUser ? actionUser.advancement : 0,
              award: actionUser ? actionUser.award : 0,
            };
          });
        }
        let selectedInfo;
        if (this.userToEdit) {
          selectedInfo = Array.from(this.userToEdit)[0];
        } else {
          selectedInfo = Array.from(this.selectedMembers)[0];
        }
        const [selectedEmail, selectedLevel, selectedJob] =
          selectedInfo.split(" - ");
        const selectedEmailLevelJob = [
          selectedEmail,
          selectedLevel,
          selectedJob,
        ];

        selectedMember = membersToSend.find((member) => {
          return (
            member.email === selectedEmailLevelJob[0] &&
            member.level_name === selectedEmailLevelJob[1] &&
            member.job_description === selectedEmailLevelJob[2]
          );
        });
        selectedMember.award = parseInt(this.points);
      } else {
        membersToSend = this.selectedCurrentUsers.map((user) => {
          const [email, levelName, jobDescription] = user.split(" - ");

          const actionUser = this.selectedAction.users.find(
            (actionUser) =>
              actionUser.email === email &&
              actionUser.level_name === levelName &&
              actionUser.job_description === jobDescription
          );
          return {
            email: email,
            level_name: levelName,
            job_description: jobDescription,
            advancement: actionUser ? actionUser.advancement : 0,
            award: actionUser
              ? actionUser.award
              : this.selectedAction.action_details.award,
          };
        });
      }
      const payload = {
        organization:
          this.currentOrganization.name ||
          this.currentOrganization.organization,
        project: this.currentProject.name,
        phase: phase,
        category: category,
        users: this.isGroupedView ? membersToSend : [selectedMember],
        levels: this.isGroupedView ? this.selectedCurrentLevels : [],
        roles: this.isGroupedView ? this.selectedRoles : [],
        action: this.actionTitle,
        subject: this.subjectText,
        deadline: formattedDateString,
        advancement: this.selectedAction.average_advancement,
        award: parseInt(this.points),
      };

      const { data, res } = await ActionsService.edit_action({
        type: this.isGroupedView ? "grouped" : "unique",
        action_id: this.selectedAction.action_details.id,
        _id: this.selectedAction.action_details._id,
        action_data: payload,
      });
      if (data.success) {
        showMessage("success", "Successfully edited action");
        this.close();

        this.$parent.editedAction(payload, this.selectedAction);
      } else {
        showMessage("success", "Could not edit the action");
      }
    },
  },
};
</script>

<style scoped>
:root {
  --vs-search-input-placeholder-color: #bdbdbd;
  --vs-font-size: 14px;
}
.style-chooser .vs__selected-options {
  display: flex;
  align-items: center;
  overflow: hidden;
  max-height: 28px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.filter_btn {
  cursor: pointer;
  font-size: 14px;
}
.filter_btn:hover {
  color: #0071ce;
}
.filter_btn.selected {
  color: #0071ce;
  font-weight: bold;
  letter-spacing: -0.02em;
  border-bottom: 2px solid #0071ce;
}
.modal-body.editAction {
  max-height: 520px !important;
}
::v-deep .modal-dialog {
  top: unset !important;
  left: unset !important;
}

.modal-body {
  padding: 0 1rem !important;
}
::v-deep .modal-header {
  padding: 1rem 1rem 0 1rem !important;
}
.modal-confirm {
  border-radius: 0px 0px 0px 25px;
  padding: 10px;
  width: 97px;
  margin-left: 10px;
}

::v-deep .el-select__wrapper {
  border-radius: 0;
}

::v-deep .el-input__wrapper {
  border-radius: 0;
}
::v-deep .el-textarea__inner {
  border-radius: 0;
}
::v-deep .modal-footer .GroupedFooter {
  padding: 0.75rem 28px;
}

::v-deep .modal-footer .NotGroupedFooter {
  padding: 0 15px;
}
</style>
